<template>
  <v-card>
    <v-layout>
      <v-navigation-drawer
        v-model="drawer"
        :rail="rail"
        permanent
        @click="rail = false"
      >
        <v-list-item
          :title=this.user_name
          nav
        >
          <template v-slot:append>
            <v-btn
              variant="text"
              icon="mdi-chevron-left"
              @click.stop="rail = !rail"
            ></v-btn>
          </template>
        </v-list-item>

        <v-divider></v-divider>

        <v-list density="compact" nav>
          <v-list-item prepend-icon="mdi-home-city" title="ホーム" value="home" to="/home"></v-list-item>
          <v-list-item prepend-icon="mdi-account" title="顧客管理" value="customer" to="/customer"></v-list-item>
          <div v-for="authority in authority_id" :key="authority">
            <v-list-item v-if="authority.app_func==3" prepend-icon="mdi-calendar-question" title="SES要員探し" value="Looking_personnel" to="/Looking_personnel"></v-list-item>
            <v-list-item v-if="authority.app_func==4" prepend-icon="mdi-calendar-range" title="SES案件探し" value="Looking_deals" to="/Looking_deals"></v-list-item>
            <v-list-item v-if="authority.app_func==5" prepend-icon="mdi-book-open" title="見積管理" value="estimate" to="/estimate"></v-list-item>
            <v-list-item v-if="authority.app_func==6" prepend-icon="mdi-store" title="受注管理" value="order" to="/order"></v-list-item>
            <v-list-item v-if="authority.app_func==7" prepend-icon="mdi-cart" title="発注管理" value="hachu" to="/hachu"></v-list-item>
            <v-list-item v-if="authority.app_func==8" prepend-icon="mdi-chart-line" title="帳票出力" value="report" to="/report"></v-list-item>
          </div>
          <v-list-item prepend-icon="mdi-logout" title="ログアウト" value="logout" @click="logout()"></v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-main>

<br><br><br><br><br><br><br><br><br><br><br><br>
<br><br><br><br><br><br><br><br><br><br><br><br>
      </v-main>
    </v-layout>
  </v-card>
</template>


<script>
  import axios from 'axios';
  export default {
    data () {
      return {
        drawer: true,
        items: [],
        rail: true,
        user_name: "",
      }
    },
    mounted() {
      const header = {
        headers: { Authorization: "JWT " + this.$store.state.token }
      }
      const hp_url = "https://nuage-sys.com"
      axios
        .get(hp_url + '/api/auth/users/me/', header)
        .then(response => {
          if (response.status !== 200) {
            this.message = 'セッションが切れましたので再度ログインをしてください。'
            this.$router.push('/')
          } else {
            this.$store.commit("setlogin", response.data.id)
            let login_id = this.$store.state.login_id
            Promise.all(
              [axios.get(hp_url + "/production/login_info/" + login_id + "/", header),
               axios.get(hp_url + "/production/login_company/" + login_id + "/", header),
               axios.get(hp_url + "/login_authority/" + login_id + "/", header),
              ]
            )
            .then(([user_info, login_company, login_authority]) => {
              this.$store.commit("setcompany", login_company.data[0]["company"])
              this.$store.commit("setauthority", login_authority.data)
              let authority_id = this.$store.state.authority_id

              if ( authority_id.length != 0 ) {
                this.user_name = user_info.data[0]["last_name"] + " " + user_info.data[0]["first_name"]
                this.authority_id = authority_id
              } else {
                this.$swal.fire('権限がありません。!', '', 'error')
                this.$router.push('/')
              }
            })
            .catch(error => {
              console.log(error)
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    methods: {
      logout: function () {
        const header = {
          headers: { Authorization: "JWT " + this.$store.state.token }
        }
        const hp_url = "https://nuage-sys.com"
        axios
          .get(hp_url + "/api/auth/logout/", header)
          .then(response => (this.logout = response.data))
          .catch(error => (this.error = error))
        this.$store.commit("setToken", "")
        this.$store.commit("setlogin", "")
        this.$router.push({ path: "/"})
      },
    }
  }
</script>