import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import { createHead } from "@vueuse/head"
import VueLazyload from 'vue3-lazyload'
import VueGtag from "vue-gtag-next"
import VueGoodTablePlugin from 'vue-good-table-next'
import VueBlocksTree from 'vue3-blocks-tree'
import { VueFlow  } from '@vue-flow/core'
import VueSweetalert2 from 'vue-sweetalert2'
import SweetAlertIcons from 'vue-sweetalert-icons'
// import the styles
import 'vue-good-table-next/dist/vue-good-table-next.css'
import 'vue3-blocks-tree/dist/vue3-blocks-tree.css'
import "@vue-flow/core/dist/style.css"
import "@vue-flow/core/dist/theme-default.css"
import 'sweetalert2/dist/sweetalert2.min.css'

const head = createHead()

loadFonts()

let defaultoptions = {treeName:'blocks-tree'}

const options = {
  confirmButtonColor: '#41b882',
  cancelButtonColor: '#ff7674',
};

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(head)
  .use(VueGoodTablePlugin)
  .use(VueBlocksTree,defaultoptions)
  .use(VueFlow)
  .use(VueSweetalert2, options)
  .use(SweetAlertIcons)
  .use(VueLazyload, {
    preLoad: 1.3,
    error: 'https://dummyimage.com/130x120/ccc/999.png&text=Not+Found',
    loading: 'https://dummyimage.com/130x120/dcdcdc/999.png&text=Now loading',
    attempt: 1
  })
  .use(VueGtag, { property: { id: 'G-V9KYMVSXNY', router } })
  .mount('#app')
