<template>
  <div>
    <Header></Header>
    <Hachu></Hachu>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/modules/Header.vue';
import Footer from '@/components/modules/Footer.vue';
import Hachu from '@/components/modules/hachu.vue';

export default {
  components: {
    Header,
    Footer,
    Hachu,
  },
};
</script>

