<template>
  <div>
    <Header></Header>
    <Looking_personnel_detail></Looking_personnel_detail>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/modules/Header.vue';
import Footer from '@/components/modules/Footer.vue';
import Looking_personnel_detail from '@/components/modules/looking_personnel_detail.vue';

export default {
  components: {
    Header,
    Footer,
    Looking_personnel_detail,
  },
};
</script>

