<template>
  <v-card>
    <v-layout>
      <v-navigation-drawer
        v-model="drawer"
        :rail="rail"
        permanent
        @click="rail = false"
      >
        <v-list-item
          :title=user_name
          nav
        >
          <template v-slot:append>
            <v-btn
              variant="text"
              icon="mdi-chevron-left"
              @click.stop="rail = !rail"
            ></v-btn>
          </template>
        </v-list-item>

        <v-divider></v-divider>

        <v-list density="compact" nav>
          <v-list-item prepend-icon="mdi-home-city" title="ホーム" value="home" to="/home"></v-list-item>
          <v-list-item prepend-icon="mdi-account" title="顧客管理" value="customer" to="/customer"></v-list-item>
          <div v-for="authority in authority_id" :key="authority">
            <v-list-item v-if="authority.app_func==3" prepend-icon="mdi-calendar-question" title="SES要員探し" value="Looking_personnel" to="/Looking_personnel"></v-list-item>
            <v-list-item v-if="authority.app_func==4" prepend-icon="mdi-calendar-range" title="SES案件探し" value="Looking_deals" to="/Looking_deals"></v-list-item>
            <v-list-item v-if="authority.app_func==5" prepend-icon="mdi-book-open" title="見積管理" value="estimate" to="/estimate"></v-list-item>
            <v-list-item v-if="authority.app_func==6" prepend-icon="mdi-store" title="受注管理" value="order" to="/order"></v-list-item>
            <v-list-item v-if="authority.app_func==7" prepend-icon="mdi-cart" title="発注管理" value="hachu" to="/hachu"></v-list-item>
            <v-list-item v-if="authority.app_func==8" prepend-icon="mdi-chart-line" title="帳票出力" value="report" to="/report"></v-list-item>
          </div>
          <v-list-item prepend-icon="mdi-logout" title="ログアウト" value="logout" @click="logout()"></v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-main>
        <v-container>
          <v-breadcrumbs :items="pankuzu">
            <template v-slot:divider>
              <v-icon icon="mdi-chevron-right"></v-icon>
            </template>
          </v-breadcrumbs>
          <v-row justify="center">
            <v-col cols="12" sm="12" md="12" lg="12">
              <h1 class="ml-3">顧客詳細</h1>
              <span v-if="customer_id == '0'">
                <v-btn class="ma-3" variant="outlined" @click="detail_upd('0')">登録</v-btn>
              </span>
              <span v-else>
                <v-btn class="ma-3" variant="outlined" @click="detail_upd(customer_info.id)">更新</v-btn>
              </span>
              <div class="mb-5">
                <v-form fast-fail @submit.prevent="customerinfo_upd">
                  <v-container>
                    <v-row justify="center">
                      <v-col cols="12" sm="12" md="6" lg="6">
                        <v-text-field
                          v-model="name"
                          label="顧客名"
                          clear-icon="mdi-close-circle"
                          clearable
                          required
                        ></v-text-field>
                        <v-text-field
                          v-model="kana"
                          label="顧客名カナ"
                          clear-icon="mdi-close-circle"
                          clearable
                        ></v-text-field>
                        <v-text-field
                          v-model="daihyou"
                          label="代表"
                          clear-icon="mdi-close-circle"
                          clearable
                        ></v-text-field>
                        <v-text-field
                          v-model="daihyou_tel"
                          label="代表電話番号"
                          clear-icon="mdi-close-circle"
                          clearable
                        ></v-text-field>
                        <v-text-field
                          v-model="daihyou_fax"
                          label="代表FAX"
                          clear-icon="mdi-close-circle"
                          clearable
                        ></v-text-field>
                        <v-text-field
                          v-model="url"
                          label="ホームページURL"
                          :loading="loading"
                          append-inner-icon="mdi-magnify"
                          single-line
                          hide-details
                          clear-icon="mdi-close-circle"
                          clearable
                          @click:append-inner="urlClick(url)"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6">
                        <v-text-field
                          v-model="postcode"
                          label="郵便番号"
                          clear-icon="mdi-close-circle"
                          clearable
                          @change="searchAddress(postcode)"
                        ></v-text-field>
                        <v-text-field
                          v-model="prefectures"
                          label="都道府県"
                          clear-icon="mdi-close-circle"
                          clearable
                        ></v-text-field>
                        <v-text-field
                          v-model="municipalities"
                          label="市区町村"
                          clear-icon="mdi-close-circle"
                          clearable
                        ></v-text-field>
                        <v-text-field
                          v-model="address1"
                          label="番地"
                          clear-icon="mdi-close-circle"
                          clearable
                        ></v-text-field>
                        <v-text-field
                          v-model="address2"
                          label="ビル・建物名"
                          clear-icon="mdi-close-circle"
                          clearable
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" class="mb-15" v-if="customer_id != '0'">
              <h1 class="ml-3">担当者</h1>
              <v-row>
                <v-dialog
                  v-model="dialog"
                  persistent
                  width="1024"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                      color="primary"
                      v-bind="props"
                      class="ma-3 ml-5"
                    >
                      新規追加
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="mt-3 ml-3 mb-n5">
                      <span class="text-h5">担当者追加</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                          >
                            <v-text-field
                              v-model="tantou"
                              label="担当者名*"
                              clear-icon="mdi-close-circle"
                              clearable
                              required
                            ></v-text-field>
                            <v-text-field
                              v-model="tantou_kana"
                              label="担当者カナ"
                              clear-icon="mdi-close-circle"
                              clearable
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                          >
                            <v-text-field
                              v-model="tantou_department"
                              label="担当者部署"
                              clear-icon="mdi-close-circle"
                              clearable
                            ></v-text-field>
                            <v-text-field
                              v-model="tantou_position"
                              label="担当者役職"
                              clear-icon="mdi-close-circle"
                              clearable
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                          >
                            <v-text-field
                              v-model="tantou_tel"
                              label="担当者電話番号"
                              clear-icon="mdi-close-circle"
                              clearable
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                          >
                            <v-text-field
                              v-model="tantou_mail"
                              label="担当者メール"
                              clear-icon="mdi-close-circle"
                              clearable
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                          >
                            <v-textarea
                              v-model="tantou_comment"
                              label="コメント"
                              rows="2"
                              row-height="15"
                              clear-icon="mdi-close-circle"
                              clearable
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-container>
                      <small>*必須項目</small>
                    </v-card-text>
                    <v-card-actions class="mt-n10">
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue-darken-1"
                        variant="text"
                        @click="dialog = false"
                      >
                        閉じる
                      </v-btn>
                      <v-btn
                        color="blue-darken-1"
                        variant="text"
                        @click="tantou_add(); dialog = false"
                      >
                        登録
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
              <vue-good-table
                :columns="tantou_columns"
                :rows="tantou_rows"
                :line-numbers="true"
                compactMode
                class="mt-3"
              >
                <template #table-row="props">
                  <span v-if="props.column.field == 'tantou_action'">
                    <button class="button-outline" @click="tantou_edit(props.row.id)">{{props.row.tantou_action}}</button>
                  </span>
                  <span v-if="props.column.field == 'tantou_comment'">
                    <button class="button-outline">
                      {{props.row.tantou_comment}}

                      <v-overlay
                        activator="parent"
                        location-strategy="connected"
                        scroll-strategy="close"
                      >
                        <v-card class="pa-2">
                          {{props.row.tantou_comment_d}}
                        </v-card>
                      </v-overlay>
                    </button>
                  </span>
                  <span v-if="props.column.field == 'tantou_delete'">
                    <button class="button-outline" @click="tantou_del(props.row.id)">{{props.row.tantou_delete}}</button>
                  </span>
                </template>
                <template #emptystate>
                  担当者は設定されていません。
                </template>
              </vue-good-table>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" class="mb-15" v-if="customer_id != '0'">
              <h1 class="ml-3">アクション</h1>
              <v-row>
                <v-dialog
                  v-model="dialog_action"
                  persistent
                  width="1024"
                >
                  <template v-slot:activator="{ props }">
                    <v-btn
                      color="primary"
                      v-bind="props"
                      class="ma-3 ml-5"
                    >
                      アクション追加
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="mt-3 ml-3 mb-n5">
                      <span class="text-h5">アクション追加</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="12"
                          >
                            <v-combobox
                              v-model="action_nichiji"
                              type="datetime-local"
                              label="日時*"
                              clear-icon="mdi-close-circle"
                              clearable
                            ></v-combobox>
                            <v-combobox
                              v-model="action_kbn"
                              :items=action_kbn_items
                              label="分類"
                              clear-icon="mdi-close-circle"
                              clearable
                            ></v-combobox>
                            <v-combobox
                              v-model="action_tantou"
                              :items=action_tantou_items
                              label="担当者"
                              return-object
                              clear-icon="mdi-close-circle"
                              clearable
                            ></v-combobox>
                            <v-textarea
                              v-model="action_comment"
                              label="コメント"
                              rows="5"
                              row-height="15"
                              clear-icon="mdi-close-circle"
                              clearable
                            ></v-textarea>
                          </v-col>
                        </v-row>
                      </v-container>
                      <small>*必須項目</small>
                    </v-card-text>
                    <v-card-actions class="mt-n10">
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue-darken-1"
                        variant="text"
                        @click="dialog_action = false"
                      >
                        閉じる
                      </v-btn>
                      <v-btn
                        color="blue-darken-1"
                        variant="text"
                        @click="action_add(); dialog_action = false"
                      >
                        登録
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-row>
              <vue-good-table
                :columns="action_columns"
                :rows="action_rows"
                :line-numbers="true"
                compactMode
                class="mt-3"
              >
                <template #table-row="props">
                  <span v-if="props.column.field == 'action_delete'">
                    <button class="button-outline" @click="action_del(props.row.action_id)">{{props.row.action_delete}}</button>
                  </span>
                </template>
                <template #emptystate>
                  担当者は設定されていません。
                </template>
              </vue-good-table>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" class="mb-15" v-if="customer_id != '0'">
              <h1 class="ml-3">契約情報</h1>
              <v-card>
                <v-tabs
                  v-model="tab"
                  color="deep-purple-accent-4"
                  align-tabs="center"
                >
                  <v-tab :value="1">全部</v-tab>
                  <v-tab :value="2">見積</v-tab>
                  <v-tab :value="3">受注</v-tab>
                  <v-tab :value="4">発注</v-tab>
                  <v-tab :value="5">請求</v-tab>
                  <v-tab :value="6">支払</v-tab>
                </v-tabs>
                <v-window v-model="tab">
                  <v-window-item
                    :value="1"
                  >
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12">
                          <v-container>
                            <v-row justify="center">
                              <v-col cols="12" sm="12" md="12" lg="12">
                                <div class="mb-15">
                                  <vue-good-table
                                    v-on:selected-rows-change="selectionChanged"
                                    :columns="all_contract_columns"
                                    :rows="all_contract_rows"
                                    compactMode
                                    style-class="vgt-table striped"
                                    :pagination-options="{
                                      enabled: true,
                                      mode: 'records',
                                      perPage: 100,
                                      position: 'top',
                                      perPageDropdown: [10, 30, 50, 100],
                                      dropdownAllowAll: false,
                                      nextLabel: '次',
                                      prevLabel: '前',
                                      rowsPerPageLabel: '表示数',
                                      ofLabel: 'の',
                                      pageLabel: 'ページ', // for 'pages' mode
                                      allLabel: '全て',
                                      infoFn: (params) => `${params.firstRecordOnPage} - ${params.lastRecordOnPage}`,
                                    }"
                                  >
                                    <template #table-row="props">
                                      <span v-if="props.column.field == 'contract_id'">
                                        <button class="button-outline" @click="estimate_report(props.row.id)">{{props.row.contract_id}}</button>
                                      </span>
                                    </template>
                                    <template #emptystate>
                                      検索結果は0件です
                                    </template>
                                  </vue-good-table>
                                </div>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-window-item>
                  <v-window-item
                    :value="2"
                  >
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12">
                          <v-container>
                            <v-row justify="center">
                              <v-col cols="12" sm="12" md="12" lg="12">
                                <div class="mb-15">
                                  <vue-good-table
                                    v-on:selected-rows-change="selectionChanged"
                                    :columns="estimate_columns"
                                    :rows="estimate_rows"
                                    compactMode
                                    style-class="vgt-table striped"
                                    :pagination-options="{
                                      enabled: true,
                                      mode: 'records',
                                      perPage: 100,
                                      position: 'top',
                                      perPageDropdown: [10, 30, 50, 100],
                                      dropdownAllowAll: false,
                                      nextLabel: '次',
                                      prevLabel: '前',
                                      rowsPerPageLabel: '表示数',
                                      ofLabel: 'の',
                                      pageLabel: 'ページ', // for 'pages' mode
                                      allLabel: '全て',
                                      infoFn: (params) => `${params.firstRecordOnPage} - ${params.lastRecordOnPage}`,
                                    }"
                                  >
                                    <template #table-row="props">
                                      <span v-if="props.column.field == 'cont_link'">
                                        <button class="button-outline" @click="estimate_report(props.row.id)">{{props.row.cont_link}}</button>
                                      </span>
                                      <span v-if="props.column.field == 'attach_link'">
                                        <button class="button-outline">
                                          {{props.row.attach_link}}

                                          <v-overlay
                                            activator="parent"
                                            location-strategy="connected"
                                            scroll-strategy="close"
                                          >
                                            <v-card>
                                              <div>
                                                <ul class="flex">
                                                  <li class="flex-col" v-for="(attach_f,index) in attach_files.value" :key="index">
                                                    <div v-if="props.row.id == attach_f.fields.no">
                                                      <div style="position: relative;">
                                                        <img class="file_icon" src="/media/documents/production/file.png" @dblclick="download_File(attach_f.pk,attach_f.fields.attach)">
                                                      </div>
                                                      <span>{{ attach_f.fields.role_name }}</span>
                                                    </div>
                                                  </li>
                                                </ul>
                                              </div>
                                            </v-card>
                                          </v-overlay>
                                        </button>
                                      </span>
                                    </template>
                                    <template #emptystate>
                                      検索結果は0件です
                                    </template>
                                  </vue-good-table>
                                </div>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-window-item>
                  <v-window-item
                    :value="3"
                  >
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12">

                        </v-col>
                      </v-row>
                    </v-container>
                  </v-window-item>
                  <v-window-item
                    :value="4"
                  >
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12">

                        </v-col>
                      </v-row>
                    </v-container>
                  </v-window-item>
                  <v-window-item
                    :value="5"
                  >
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12">

                        </v-col>
                      </v-row>
                    </v-container>
                  </v-window-item>
                  <v-window-item
                    :value="6"
                  >
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12">

                        </v-col>
                      </v-row>
                    </v-container>
                  </v-window-item>
                </v-window>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-layout>
  </v-card>
</template>

<style>
.button-outline {
  background-color: #0a5d8a;
  width: 70px;
  height: 30px;
  color: #FFFFFF;
  font-size: 10px;
  border-radius: 10px;
}

</style>

<script setup>
import { ref, reactive, onMounted, inject } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex'
import { useRouter, useRoute } from "vue-router"
import { Core as YubinBangoCore } from "yubinbango-core2";
import dayjs from "dayjs";
dayjs.locale("ja");

const router = useRouter()
const route = useRoute()
const store = useStore()
const swal = inject('$swal')


const drawer = ref(true)
const rail = ref(true)
const dialog = ref(false)
const dialog_action = ref(false)
const tab = ref(null)

const dt = new Date();
const y = dt.getFullYear();
const m = ("00" + (dt.getMonth()+1)).slice(-2);
const d = ("00" + dt.getDate()).slice(-2);
const today = y + "-" + m + "-" + d;

const login_id = store.state.login_id
const company_id = store.state.company_id
const customer_id = route.params.customer_id
const authority_id = store.state.authority_id

const hp_url = "https://nuage-sys.com"

const pankuzu = reactive([
  {title: 'ホーム', disabled: false, href: '../home',},
  {title: '顧客情報', disabled: false, href: '../customer',},
  {title: '顧客詳細', disabled: true,},
])


const tantou_columns = reactive([
  {label: '処理',field: 'tantou_action', html: true,},
  {label: 'ID', field: 'tantou_id', type: 'number', hidden: true,},
  {label: '名前', field: 'tantou',},
  {label: 'カナ', field: 'tantou_kana',},
  {label: '部署', field: 'tantou_department',},
  {label: '役職', field: 'tantou_position',},
  {label: '電話番号', field: 'tantou_tel',},
  {label: 'メール', field: 'tantou_mail',},
  {label: 'メモ', field: 'tantou_comment', html: true,},
  {label: '削除', field: 'tantou_delete', html: true,},
])


const all_contract_columns = reactive([
  {label: '契約ID',field: 'contract_id',filterOptions: {enabled: true,}},
  {label: '区分',field: 'kbn',filterOptions: {enabled: true,}},
  {label: '番号',field: 'No',filterOptions: {enabled: true,}},
  {label: '版数',field: 'version',filterOptions: {enabled: true,}},
  {label: '最終承認日',field: 'date',filterOptions: {enabled: true,}},
  {label: '金額',field: 'total',html: true,filterOptions: {enabled: true,}},
])


const estimate_columns = reactive([
  {label: '見積番号',field: 'estimate_no', filterOptions: {enabled: true,}},
  {label: '版数',field: 'version', filterOptions: {enabled: true,}},
  {label: '件名',field: 'subject', filterOptions: {enabled: true,}},
  {label: '作成者',field: 'created_user', filterOptions: {enabled: true,}},
  {label: '最終承認者',field: 'approval_user', filterOptions: {enabled: true,}},
  {label: '最終承認日',field: 'date', filterOptions: {enabled: true,}},
  {label: '契約ID',field: 'contract_id', html: true, filterOptions: {enabled: true,}},
  {label: '見積内容',field: 'cont_link', html: true,},
  {label: '添付',field: 'attach_link', html: true,},
])


const action_columns = reactive([
  {label: 'ID', field: 'action_id', type: 'number', hidden: true,},
  {label: '日時', field: 'action_nichiji', filterOptions: {enabled: true,}},
  {label: '分類', field: 'action_kbn', filterOptions: {enabled: true,}},
  {label: '担当者', field: 'action_tantou', filterOptions: {enabled: true,}},
  {label: '記入者', field: 'action_kinyu', filterOptions: {enabled: true,}},
  {label: '内容', field: 'action_comment', filterOptions: {enabled: true,}},
  {label: '削除', field: 'action_delete', html: true,},
])

function format(date) {
  date = dayjs(date).format("YYYY/MM/DD HH:mm");
  return date
}

const header = {
   headers: { Authorization: `JWT ` + store.state.token }
}

const tantou = ref("")
const tantou_kana = ref("")
const tantou_department = ref("")
const tantou_position = ref("")
const tantou_tel = ref("")
const tantou_comment = ref("")
const tantou_mail = ref("")
const tantou_rows = reactive([])
function tantou_data(tantou_info) {
  tantou_info.data.forEach(element =>
    tantou_rows.push({
      id: String(element["id"]),
      tantou: String(element["tantou"]).replace(null, ''),
      tantou_kana: String(element["tantou_kana"]).replace(null, ''),
      tantou_department: String(element["tantou_department"]).replace(null, ''),
      tantou_position: String(element["tantou_position"]).replace(null, ''),
      tantou_tel: String(element["tantou_tel"]).replace(null, ''),
      tantou_comment_d: String(element["comment"]).replace(null, ''),
      tantou_mail: String(element["tantou_mail"]).replace(null, ''),
      tantou_action: '編集',
      tantou_comment: '表示',
      tantou_delete: '削除',
    })
  )
}


const all_contract_rows = reactive([])
function contract_data(contract_info) {
  contract_info.data.forEach(element => {
    let kbn = 0
    if (element["kbn"] == 1) {
      kbn = "見積"
    } else if (element["kbn"] == 2) {
      kbn = "受注"
    } else if (element["kbn"] == 3) {
      kbn = "発注"
    } else if (element["kbn"] == 4) {
      kbn = "請求"
    } else if (element["kbn"] == 5) {
      kbn = "支払"
    }
    all_contract_rows.push({
      contract_id: String(element["contract_id"]).replace(null, ''),
      kbn: kbn,
      No: String(element["no"]).replace(null, ''),
      version: String(element["version"]).replace(null, ''),
      date: String(element["date"]).replace(null, ''),
      total: element["total"],
    })
  })
}

const estimate_rows = reactive([])
function estimate_data(estimate_info,com_user_info) {
  estimate_info.data.forEach(element => {
    const com_user_info_t = com_user_info.data.filter(com_user => {
      return com_user.id == element["created_user"]
    })
    const com_user_info_m = com_user_info.data.filter(com_user => {
      return com_user.id == element["approval_user"]
    })

    estimate_rows.push({
      id: String(element["id"]),
      estimate_no: String(element["estimate_no"]),
      version: String(element["version"]),
      subject: String(element["subject"]).replace(null, ''),
      created_user: com_user_info_t[0].last_name + " " + com_user_info_t[0].first_name,
      approval_user: com_user_info_m[0].last_name + " " + com_user_info_m[0].first_name,
      date: String(element["approval_date"]).replace(null, ''),
      contract_id: String(element["contract_id"]).replace(null, ''),
      cont_link: '表示',
      attach_link: '表示',
    })
  })
}

const action_nichiji = ref("")
const action_kbn = ref("")
const action_tantou = ref("")
const action_comment = ref("")
const action_rows = reactive([])
function action_data(action_info,com_user_info) {
  action_info.data.forEach(element => {

    const com_user_info_t = com_user_info.data.filter(com_user => {
      return com_user.id == element["created_user"]
    })

    action_rows.push({
      action_id: String(element["id"]),
      action_nichiji: format(String(element["action_nichiji"]).replace(null, '')),
      action_kbn: String(element["action_kbn"]).replace(null, ''),
      action_tantou: String(element["action_tantou"]).replace(null, ''),
      action_kinyu: com_user_info_t[0].last_name + " " + com_user_info_t[0].first_name,
      action_comment: String(element["action_comment"]).replace(null, ''),
      action_delete: '削除',
    })
  })
}


function logout() {
  axios
    .get(hp_url + "/api/auth/logout/", header)
    .then(response => (logout.value = response.data))
    .catch(error => (this.error = error))
  store.commit("setToken", "")
  store.commit("setlogin", "")
  router.push({ path: "/"})
}

const postcode = ref("")
const prefectures = ref("")
const municipalities = ref("")
const address1 = ref("")
function searchAddress (postcode) {
  if (!postcode.match(/^\d{3}-?\d{4}$/)) { //入力された値をチェック
    return false
  }
  new YubinBangoCore(this.postcode, (value) => {
    prefectures.value = value.region; // 都道府県 IDを取得したい時はregion_idと記述
    municipalities.value = value.locality; // 市区町村
    address1.value += value.street; // 町域
  });
}


function urlClick (url) {
  window.location.href = url
}

const name = ref("")
const kana = ref("")
const daihyou = ref("")
const daihyou_tel = ref("")
const daihyou_fax = ref("")
const address2 = ref("")
const comment = ref("")
const url = ref("")
function detail_upd(e) {
  swal({
    title: 'データの登録確認',
    text: '入力内容をサーバへ送信してもよろしいですか?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: '登録する',
    cancelButtonText: 'キャンセル',
  }).then(result => {
    if (result.value) {
      if (e == '0') {
        const customer_data = {
          name: name.value,
          kana: kana.value,
          daihyou: daihyou.value,
          daihyou_tel: daihyou_tel.value,
          daihyou_fax: daihyou_fax.value,
          postcode: postcode.value,
          prefectures: prefectures.value,
          municipalities: municipalities.value,
          address1: address1.value,
          address2: address2.value,
          comment: comment.value,
          url: url.value,
          start_date: today,
          end_date: "9999-12-31",
          company: company_id,
          created_pg: "costomer_detail_create",
          created_user: login_id,
          uploaded_pg: "costomer_detail_create",
          uploaded_user: login_id,
        }
        axios
          .post(hp_url + "/production/customer/create/", customer_data, header)
          .then(response => {
            var customer_updres = response.data;
            customer_updres.value = customer_updres
            router.push('/customer')
          })
          .catch(error => (this.error = error))
        swal.fire('新規登録完了しました。!', '', 'success')
      } else {
        const customer_data = {
          name: name.value,
          kana: kana.value,
          daihyou: daihyou.value,
          daihyou_tel: daihyou_tel.value,
          daihyou_fax: daihyou_fax.value,
          postcode: postcode.value,
          prefectures: prefectures.value,
          municipalities: municipalities.value,
          address1: address1.value,
          address2: address2.value,
          comment: comment.value,
          company: company_id,
          uploaded_pg: "costomer_detail_update",
          uploaded_user: login_id,
        }
        axios
          .put(hp_url + "/production/customer/upd/" + customer_id + "/", customer_data, header)
          .then(response => {
            var customer_updres = response.data;
            customer_updres.value = customer_updres
            router.push('/customer')
          })
          .catch(error => (this.error = error))
        swal.fire('更新完了しました。!', '', 'success')
      }
    }
  })
}


function tantou_add() {
  const tantou_dat = {
    tantou: tantou.value,
    tantou_kana: tantou_kana.value,
    tantou_department: tantou_department.value,
    tantou_position: tantou_position.value,
    tantou_tel: tantou_tel.value,
    tantou_mail: tantou_mail.value,
    comment: tantou_comment.value,
    status: 1,
    start_date: today,
    end_date: "9999-12-31",
    customer: route.params.customer_id,
    created_pg: "costomer_detail_create",
    created_user: login_id,
    uploaded_pg: "costomer_detail_create",
    uploaded_user: login_id,
  }
  axios
    .post(hp_url + "/production/tantou/create/", tantou_dat, header)
    .then(response => {
      var tantou_updres = response.data;
      tantou_updres.value = tantou_updres
      router.go({path: router.currentRoute.path, force: true})
    })
    .catch(error => (this.error = error))
}

function tantou_edit(tanto_id) {
  router.push('/customer/tantou/' + customer_id + "/" + tanto_id)
}


function tantou_del(tanto_id) {
  swal({
    title: 'データの削除確認',
    text: '本当に削除してもよろしいですか?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: '削除する',
    cancelButtonText: 'キャンセル',
  }).then(result => {
    if (result.value) {
      const tantou_da_del = {
        status: '2',
        end_date: today,
        uploaded_pg: "costomer_detail_del",
        uploaded_user: login_id,
        customer: route.params.customer_id,
      }
      axios
        .put(hp_url + "/production/tantou/upd/" + tanto_id + "/", tantou_da_del, header)
        .then(response => {
          var tantou_updres = response.data;
          tantou_updres.value = tantou_updres
          router.go({path: router.currentRoute.path, force: true})
        })
        .catch(error => (this.error = error))
    }
  })
}


function action_add() {
  const action_dat = {
    action_nichiji: action_nichiji.value,
    action_kbn: action_kbn.value,
    action_tantou: action_tantou.value,
    action_comment: action_comment.value,
    status: 1,
    customer: route.params.customer_id,
    created_pg: "costomer_detail_create",
    created_user: login_id,
    uploaded_pg: "costomer_detail_create",
    uploaded_user: login_id,
  }
  axios
    .post(hp_url + "/production/action/create/", action_dat, header)
    .then(response => {
      var action_updres = response.data;
      action_updres.value = action_updres
      router.go({path: router.currentRoute.path, force: true})
    })
    .catch(error => (this.error = error))
}



function action_del(action_id) {
  swal({
    title: 'データの削除確認',
    text: '本当に削除してもよろしいですか?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: '削除する',
    cancelButtonText: 'キャンセル',
  }).then(result => {
    if (result.value) {
      const action_da_del = {
        status: '2',
        end_date: today,
        uploaded_pg: "costomer_detail_del",
        uploaded_user: login_id,
        customer: route.params.customer_id,
      }
      axios
        .put(hp_url + "/production/action/upd/" + action_id + "/", action_da_del, header)
        .then(response => {
          var action_updres = response.data;
          action_updres.value = action_updres
          router.go({path: router.currentRoute.path, force: true})
        })
        .catch(error => (this.error = error))
    }
  })
}



const user_name = ref("")
const customer_info = reactive([]);
const action_tantou_items = reactive([]);
const action_kbn_items = reactive([]);
onMounted(() => {
  Promise.all(
    [axios.get(hp_url + "/production/login_info/" + login_id + "/", header),
     axios.get(hp_url + "/production/login_company/" + login_id + "/", header),
    ]
  )
  .then(([user_info, login_company]) => {
    let action_tantou_i = []
    let action_tantou_item = []
    let action_kbn_i = []
    let action_kbn_item = []
    store.commit("setcompany", login_company.data[0]["company"])
    let login_authority = store.state.authority_id
    if ( login_authority.length != 0 ) {
      user_name.value = user_info.data[0]["last_name"] + " " + user_info.data[0]["first_name"]
      if (customer_id == '0') {
        customer_info.value = []
      } else {
        Promise.all(
          [axios.get(hp_url + "/production/customer/upd/" + customer_id + "/", header),
           axios.get(hp_url + "/production/login_tantou/" + customer_id + "/", header),
           axios.get(hp_url + "/production/contract/customer/" + customer_id + "/", header),
           axios.get(hp_url + "/production/estimate/customer/" + customer_id + "/", header),
           axios.get(hp_url + "/production/company_user/" + company_id + "/", header),
           axios.get(hp_url + "/production/action/customer/" + customer_id + "/", header),
          ]
        )
        .then(([customer_info,tantou_info,contract_info,estimate_info,com_user_info,action_info]) => {
          customer_info.value = customer_info.data
          name.value = customer_info.data["name"]
          kana.value = customer_info.data["kana"]
          daihyou.value = customer_info.data["daihyou"]
          daihyou_tel.value = customer_info.data["daihyou_tel"]
          daihyou_fax.value = customer_info.data["daihyou_fax"]
          postcode.value = customer_info.data["postcode"]
          prefectures.value = customer_info.data["prefectures"]
          municipalities.value = customer_info.data["municipalities"]
          address1.value = customer_info.data["address1"]
          address2.value = customer_info.data["address2"]
          url.value = customer_info.data["url"]
          comment.value = customer_info.data["comment"]
          tantou_info.value = tantou_info.data
          tantou_data(tantou_info)
          contract_data(contract_info)
          estimate_data(estimate_info,com_user_info)
          action_data(action_info,com_user_info)
          action_info.data.forEach(element => {
            action_tantou_i.push(String(element["action_tantou"]).replace(null, ''))
            action_kbn_i.push(String(element["action_kbn"]).replace(null, ''))
          })
          action_tantou_item = Array.from(new Set(action_tantou_i))
          action_tantou_item.forEach(element => {
            action_tantou_items.push(element)
          })
          action_kbn_item = Array.from(new Set(action_kbn_i))
          action_kbn_item.forEach(element => {
            action_kbn_items.push(element)
          })
        })
        .catch(error => {
          console.log(error)
        })
      }
    } else {
      swal.fire('権限がありません。!', '', 'error')
      router.push('/')
    }
  })
  .catch(error => {
    console.log(error)
  })
})

</script>