import { createStore } from 'vuex'

export default createStore({
  state: {
    token: '',
    login_id: '',
    company_id: '',
  },
  getters: {
  },
  mutations: {
    setToken(state, token) {
      state.token = token
    },
    setlogin(state, login_id) {
      state.login_id = login_id
    },
    setauthority(state, authority_id) {
      state.authority_id = authority_id
    },
    setcompany(state, company_id) {
      state.company_id = company_id
    },
    setkeiyaku(state, keiyaku_code) {
      state.keiyaku_code = keiyaku_code
    },
    setkeiyakuid(state, keiyaku_id) {
      state.keiyaku_id = keiyaku_id
    },
  },
  actions: {
  },
  modules: {
  }
})
