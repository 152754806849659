<template>
  <v-card>
    <v-layout>
      <v-navigation-drawer
        v-model="drawer"
        :rail="rail"
        permanent
        @click="rail = false"
      >
        <v-list-item
          :title=user_name.value
          nav
        >
          <template v-slot:append>
            <v-btn
              variant="text"
              icon="mdi-chevron-left"
              @click.stop="rail = !rail"
            ></v-btn>
          </template>
        </v-list-item>

        <v-divider></v-divider>

        <v-list density="compact" nav>
          <v-list-item prepend-icon="mdi-home-city" title="ホーム" value="home" to="/home"></v-list-item>
          <v-list-item prepend-icon="mdi-account" title="顧客管理" value="customer" to="/customer"></v-list-item>
          <v-list-item prepend-icon="mdi-book-open" title="見積管理" value="estimate" to="/estimate"></v-list-item>
          <v-list-item prepend-icon="mdi-store" title="受注管理" value="order" to="/order"></v-list-item>
          <v-list-item prepend-icon="mdi-cart" title="発注管理" value="hachu" to="/hachu"></v-list-item>
          <v-list-item prepend-icon="mdi-chart-line" title="帳票出力" value="report" to="/report"></v-list-item>
          <v-list-item prepend-icon="mdi-logout" title="ログアウト" value="logout" @click="logout()"></v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-main>
        <v-form @submit.prevent="submit">
        <v-container>
          <v-breadcrumbs :items="pankuzu">
            <template v-slot:divider>
              <v-icon icon="mdi-chevron-right"></v-icon>
            </template>
          </v-breadcrumbs>
          <v-row justify="center">
            <v-col cols="10" sm="10" md="12" lg="12">
              <v-card>
                <h1 class="ml-3">見積作成</h1>
                <span v-if="status==99">
                </span>
                <span v-else>
                <span v-if="status==10">
                  <v-btn type="submit" class="ma-3" variant="outlined">登録</v-btn>
                  <v-btn class="ma-3" variant="outlined" @click="estimate_upd('10')">一時保存</v-btn>
                  <v-btn class="ma-3" variant="outlined" @click="estimate_edit('99')">削除</v-btn>
                </span>
                <span v-else-if="status == 20 || status == 21 || status == 22">
                  <v-btn class="ma-3" variant="outlined" @click="estimate_edit('10')">編集</v-btn>
                  <v-dialog
                    v-model="dialog_attached"
                    width="auto"
                  >
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        class="ma-3"
                        variant="outlined"
                      >
                        回覧内容・添付
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">回覧内容・添付</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="12" md="12" lg="12">
                              <v-textarea
                                v-model="content"
                                label="回覧内容"
                                rows="6"
                                row-height="15"
                              ></v-textarea>
                            </v-col>
                            <div
                              class="drop_area"
                              @dragenter="dragEnter"
                              @dragleave="dragLeave"
                              @dragover.prevent = "isEnter = false"
                              @drop.prevent="dropFile"
                              :class="{enter: isEnter}"
                            >
                              ファイルアップロード
                            </div>
                            <div>
                              <ul class="flex">
                                <li class="flex-col" v-for="(file,index) in files" :key="index" @click="deleteFile(index)">
                                  <div style="position: relative;">
                                    <span class="delete-mark">×</span>
                                    <img class="file_icon" src="/media/documents/production/file.png">
                                  </div>
                                  <span>{{ file.name }}</span>
                                </li>
                              </ul>
                            </div>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="green-darken-1"
                          variant="text"
                          @click="dialog_attached = false"
                        >
                          閉じる
                        </v-btn>
                        <v-btn
                          color="green-darken-1"
                          variant="text"
                          @click="sendFile();dialog_attached = false"
                        >
                          保存
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-btn class="ma-3" variant="outlined" @click="estimate_approval('10')">回覧実行</v-btn>
                  <v-dialog
                    v-model="dialog_wf"
                    scrollable
                    width="90%"
                  >
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        class="ma-3"
                        variant="outlined"
                      >
                        回覧ルート
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">回覧ルート</span>
                      </v-card-title>
                      <v-card-text style="height: 500px;">
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="12" md="12" lg="12">
                              <workflow style="height: 500px;"></workflow>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn color="primary" block @click="dialog_wf = false">閉じる</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog
                    v-model="dialog_wf_pro"
                    width="auto"
                  >
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        class="ma-3"
                        variant="outlined"
                      >
                        回覧履歴
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">回覧履歴</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="12" md="12" lg="12">
                              <v-table
                                fixed-header
                                height="300px"
                              >
                                <thead>
                                  <tr>
                                    <th class="text-left">
                                      No
                                    </th>
                                    <th class="text-left">
                                      処理
                                    </th>
                                    <th class="text-left">
                                      処理者
                                    </th>
                                    <th class="text-left">
                                      処理時間
                                    </th>
                                    <th class="text-left">
                                      連絡事項
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="wf_pro_i in wf_pro_info.value"
                                    :key="wf_pro_i.id"
                                  >
                                    <td>{{ wf_pro_i.no }}</td>
                                    <td>{{ wf_pro_i.user_name }}</td>
                                    <td>{{ format(wf_pro_i.process_time) }}</td>
                                    <td>{{ wf_pro_i.role_name }}</td>
                                    <td>{{ wf_pro_i.information }}</td>
                                  </tr>
                                </tbody>
                              </v-table>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn color="primary" block @click="dialog_wf_pro = false">閉じる</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>

                  <v-btn class="ma-3" variant="outlined" @click="estimate_copy()">コピー</v-btn>
                </span>
                <span v-else-if="status == 30">
                  <span v-if="pro_status == 20 && pro_hierarchy_parent != '90'">
                    <v-btn class="ma-3" variant="outlined" @click="estimate_approval('20')">承認</v-btn>
                    <v-btn class="ma-3" variant="outlined" @click="estimate_return('10')">差戻</v-btn>
                  </span>
                  <span v-if="pro_status == 20 && pro_hierarchy_parent == '90'">
                    <v-btn class="ma-3" variant="outlined" @click="estimate_approval('90')">最終承認</v-btn>
                    <v-btn class="ma-3" variant="outlined" @click="estimate_return('10')">差戻</v-btn>
                  </span>
                  <span v-if="pro_status == 90">
                    <v-btn class="ma-3" variant="outlined" @click="estimate_return('20')">取下</v-btn>
                  </span>
                  <span v-if="pro_status == 10">
                    <v-btn class="ma-3" variant="outlined" @click="estimate_approval('30')">事前承認</v-btn>
                  </span>
                  <!--<v-btn class="ma-3" variant="outlined" @click="estimate_approval('40')">代理承認</v-btn>-->
                  <v-dialog
                    v-model="dialog_wf"
                    scrollable
                    width="90%"
                  >
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        class="ma-3"
                        variant="outlined"
                      >
                        回覧ルート
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">回覧ルート</span>
                      </v-card-title>
                      <v-card-text style="height: 500px;">
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="12" md="12" lg="12">
                              <workflow style="height: 500px;"></workflow>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn color="primary" block @click="dialog_wf = false">閉じる</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog
                    v-model="dialog_wf_pro"
                    width="auto"
                  >
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        class="ma-3"
                        variant="outlined"
                      >
                        回覧履歴
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">回覧履歴</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="12" md="12" lg="12">
                              <v-table
                                fixed-header
                                height="300px"
                              >
                                <thead>
                                  <tr>
                                    <th class="text-left">
                                      No
                                    </th>
                                    <th class="text-left">
                                      役割
                                    </th>
                                    <th class="text-left">
                                      処理者
                                    </th>
                                    <th class="text-left">
                                      処理時間
                                    </th>
                                    <th class="text-left">
                                      処理内容
                                    </th>
                                    <th class="text-left">
                                      連絡事項
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="wf_pro_i in wf_pro_info.value"
                                    :key="wf_pro_i.id"
                                  >
                                    <td>{{ wf_pro_i.no }}</td>
                                    <td>{{ wf_pro_i.role_name }}</td>
                                    <td>{{ wf_pro_i.user_name }}</td>
                                    <td>{{ format(wf_pro_i.process_time) }}</td>
                                    <td>{{ wf_pro_i.status }}</td>
                                    <td>{{ wf_pro_i.information }}</td>
                                  </tr>
                                </tbody>
                              </v-table>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn color="primary" block @click="dialog_wf_pro = false">閉じる</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-btn class="ma-3" variant="outlined" @click="estimate_copy()">コピー</v-btn>
                </span>
                <span v-else-if="status == 90">
                  <v-dialog
                    v-model="dialog_wf"
                    scrollable
                    width="90%"
                  >
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        class="ma-3"
                        variant="outlined"
                      >
                        回覧ルート
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">回覧ルート</span>
                      </v-card-title>
                      <v-card-text style="height: 500px;">
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="12" md="12" lg="12">
                              <workflow style="height: 500px;"></workflow>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn color="primary" block @click="dialog_wf = false">閉じる</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-dialog
                    v-model="dialog_wf_pro"
                    width="auto"
                  >
                    <template v-slot:activator="{ props }">
                      <v-btn
                        v-bind="props"
                        class="ma-3"
                        variant="outlined"
                      >
                        回覧履歴
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="text-h5">回覧履歴</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12" sm="12" md="12" lg="12">
                              <v-table
                                fixed-header
                                height="300px"
                              >
                                <thead>
                                  <tr>
                                    <th class="text-left">
                                      No
                                    </th>
                                    <th class="text-left">
                                      処理者
                                    </th>
                                    <th class="text-left">
                                      処理時間
                                    </th>
                                    <th class="text-left">
                                      処理内容
                                    </th>
                                    <th class="text-left">
                                      連絡事項
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="wf_pro_i in wf_pro_info.value"
                                    :key="wf_pro_i.id"
                                  >
                                    <td>{{ wf_pro_i.no }}</td>
                                    <td>{{ wf_pro_i.user_name }}</td>
                                    <td>{{ format(wf_pro_i.process_time) }}</td>
                                    <td>{{ wf_pro_i.role_name }}</td>
                                    <td>{{ wf_pro_i.information }}</td>
                                  </tr>
                                </tbody>
                              </v-table>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-btn color="primary" block @click="dialog_wf_pro = false">閉じる</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                  <v-btn class="ma-3" variant="outlined" @click="estimate_copy()">コピー</v-btn>
                </span>
                <v-container>
                  <v-row justify="center">
                    <v-col cols="12" sm="12" md="12" lg="12">
                      <span v-if="status>=20">
                        <v-textarea
                          v-model="content"
                          label="回覧内容"
                          rows="6"
                          row-height="15"
                          disabled
                        ></v-textarea>
                      </span>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="12">
                      <span v-if="status>=20">
                        <div>
                          <ul class="flex">
                            <li class="flex-col" v-for="(attach_f,index) in attach_files.value" :key="index">
                              <div style="position: relative;">
                                <span v-if="status<=29">
                                  <span class="delete-mark" @click="del_send_File(attach_f.pk,index)">×</span>
                                </span>
                                <img class="file_icon" src="/media/documents/production/file.png" @dblclick="download_File(attach_f.pk,attach_f.fields.attach)">
                              </div>
                              <span>{{ attach_f.fields.role_name }}</span>
                            </li>
                          </ul>
                        </div>
                      </span>
                    </v-col>
                    <v-col cols="12" sm="12" md="5" lg="5">
                      <v-text-field
                        v-model="customer.value.value"
                        label="顧客"
                        variant="outlined"
                        :error-messages="customer.errorMessage.value"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2" sm="2" md="1" lg="1">
                      <span v-if="status==10">
                        <v-container fluid>
                          <v-row>
                            <v-col cols="2" sm="2" md="1" lg="1">
                              <v-dialog
                                v-model="dialog"
                                fullscreen
                                :scrim="false"
                                transition="dialog-bottom-transition"
                              >
                                <template v-slot:activator="{ props }">
                                  <v-btn
                                    color="primary"
                                    dark
                                    class="ml-n9 mt-n2"
                                    v-bind="props"
                                    size="large"
                                  >
                                    選択
                                  </v-btn>
                                </template>
                                <v-card>
                                  <v-toolbar
                                    dark
                                    color="primary"
                                  >
                                    <v-btn
                                      icon
                                      dark
                                      @click="dialog = false"
                                    >
                                      <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                    <v-toolbar-title>検索条件</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                  </v-toolbar>
                                  <v-title>
                                    検索ヒット件数：{{ Posts.length }}{{ text }}件
                                  </v-title>
                                  <v-container>
                                    <v-row class="mb-12">
                                      <v-container>
                                        <v-row justify="center">
                                          <v-col cols="12" sm="12" md="12" lg="12">
                                            <div class="mb-15">
                                              <vue-good-table
                                                v-on:selected-rows-change="selectionChanged"
                                                :columns="customer_columns"
                                                :rows="customer_rows"
                                                compactMode
                                                style-class="vgt-table striped"
                                                :pagination-options="{
                                                  enabled: true,
                                                  mode: 'records',
                                                  perPage: 100,
                                                  position: 'top',
                                                  perPageDropdown: [10, 30, 50, 100],
                                                  dropdownAllowAll: false,
                                                  nextLabel: '次',
                                                  prevLabel: '前',
                                                  rowsPerPageLabel: '表示数',
                                                  ofLabel: 'の',
                                                  pageLabel: 'ページ', // for 'pages' mode
                                                  allLabel: '全て',
                                                  infoFn: (params) => `${params.firstRecordOnPage} - ${params.lastRecordOnPage}`,
                                                }"
                                              >
                                                <template #table-row="props">
                                                  <span v-if="props.column.field == 'action'">
                                                    <button class="button-outline" @click="cus_sel(props.row.id,props.row.name,props.row.postcode,props.row.prefectures,props.row.municipalities,props.row.address1,props.row.address2); dialog=false">{{props.row.action}}</button>
                                                  </span>
                                                  <span v-if="props.column.field == 'estimatelink'">
                                                    <button class="button-outline" @click="estimatelink(props.row.id)">{{props.row.estimatelink}}</button>
                                                  </span>
                                                  <span v-if="props.column.field == 'contractlink'">
                                                    <button class="button-outline" @click="contractlink(props.row.id)">{{props.row.contractlink}}</button>
                                                  </span>
                                                  <span v-if="props.column.field == 'oldcontractlink'">
                                                    <button class="button-outline" @click="oldcontractlink(props.row.id)">{{props.row.oldcontractlink}}</button>
                                                  </span>
                                                </template>
                                                <template #emptystate>
                                                  検索結果は0件です
                                                </template>
                                              </vue-good-table>
                                            </div>
                                          </v-col>
                                        </v-row>
                                      </v-container>
                                    </v-row>
                                  </v-container>
                                </v-card>
                              </v-dialog>
                            </v-col>
                          </v-row>
                        </v-container>
                      </span>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6">
                      <span v-if="status==10">
                        <v-combobox
                          v-model="customer_tantou"
                          label="顧客側担当者"
                          :items=customer_tantou_items
                          clear-icon="mdi-close-circle"
                          clearable
                        ></v-combobox>
                      </span>
                      <span v-else>
                        <v-combobox
                          v-model="customer_tantou"
                          label="顧客側担当者"
                          :items=customer_tantou_items
                          variant="outlined"
                          disabled
                        ></v-combobox>
                      </span>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="12">
                      <span v-if="status==10">
                        <v-text-field
                          v-model="postcode"
                          label="郵便番号"
                          counter
                          maxlength="7"
                          clear-icon="mdi-close-circle"
                          clearable
                          @change="searchAddress"
                        ></v-text-field>
                      </span>
                      <span v-else>
                        <v-text-field
                          v-model="postcode"
                          label="郵便番号"
                          counter
                          maxlength="7"
                          @change="searchAddress"
                          variant="outlined"
                          disabled
                        ></v-text-field>
                      </span>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6">
                      <span v-if="status==10">
                        <v-text-field
                          v-model="prefectures"
                          label="都道府県"
                          clear-icon="mdi-close-circle"
                          clearable
                        ></v-text-field>
                      </span>
                      <span v-else>
                        <v-text-field
                          v-model="prefectures"
                          label="都道府県"
                          variant="outlined"
                          disabled
                        ></v-text-field>
                      </span>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6">
                      <span v-if="status==10">
                        <v-text-field
                          v-model="municipalities"
                          label="市区町村"
                          clear-icon="mdi-close-circle"
                          clearable
                        ></v-text-field>
                      </span>
                      <span v-else>
                        <v-text-field
                          v-model="municipalities"
                          label="市区町村"
                          variant="outlined"
                          disabled
                        ></v-text-field>
                      </span>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6">
                      <span v-if="status==10">
                        <v-text-field
                          v-model="address1"
                          label="住所"
                          clear-icon="mdi-close-circle"
                          clearable
                        ></v-text-field>
                      </span>
                      <span v-else>
                        <v-text-field
                          v-model="address1"
                          label="住所"
                          variant="outlined"
                          disabled
                        ></v-text-field>
                      </span>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6">
                      <span v-if="status==10">
                        <v-text-field
                          v-model="address2"
                          label="建物名・ビル名"
                          clear-icon="mdi-close-circle"
                          clearable
                        ></v-text-field>
                      </span>
                      <span v-else>
                        <v-text-field
                          v-model="address2"
                          label="建物名・ビル名"
                          variant="outlined"
                          disabled
                        ></v-text-field>
                      </span>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="12">
                    <small>※契約IDは契約に紐づけたいときに入力してください。（未入力時自動で契約IDが生成されます）
                    紐づかない契約IDを入力された場合はエラーとなります。</small>
                      <span v-if="status==10">
                        <v-text-field
                          v-model="contract_id"
                          label="契約ID"
                          clear-icon="mdi-close-circle"
                          clearable
                          @change="searchcontract(contract_id)"
                        ></v-text-field>
                        <span class="error_class" v-if="contract_flg=='N'">契約IDに紐づかないIDが入力されています。</span>
                      </span>
                      <span v-else>
                        <v-text-field
                          v-model="contract_id"
                          label="契約ID"
                          variant="outlined"
                          disabled
                        ></v-text-field>
                      </span>
                      <span v-if="status==10">
                        <v-text-field
                          v-model="date_of_issue.value.value"
                          label="発行日"
                          type="date"
                          :error-messages="date_of_issue.errorMessage.value"
                        ></v-text-field>
                      </span>
                      <span v-else>
                        <v-text-field
                          v-model="date_of_issue.value.value"
                          label="発行日"
                          type="date"
                          variant="outlined"
                          disabled
                        ></v-text-field>
                      </span>
                      <span v-if="status==10">
                        <v-text-field
                          v-model="subject"
                          label="件名"
                          maxlength="30"
                          clear-icon="mdi-close-circle"
                          clearable
                        ></v-text-field>
                      </span>
                      <span v-else>
                        <v-text-field
                          v-model="subject"
                          label="件名"
                          maxlength="30"
                          variant="outlined"
                          disabled
                        ></v-text-field>
                      </span>
                      <span v-if="status==10">
                        <v-text-field
                          v-model="period"
                          label="見積有効期限"
                          type="date"
                          clear-icon="mdi-close-circle"
                          clearable
                        ></v-text-field>
                      </span>
                      <span v-else>
                        <v-text-field
                          v-model="period"
                          label="見積有効期限"
                          type="date"
                          variant="outlined"
                          disabled
                        ></v-text-field>
                      </span>
                      <span v-if="status==10">
                        <v-select
                          v-model="payment_item"
                          label="支払条件"
                          :items=payment_items
                          clear-icon="mdi-close-circle"
                          clearable
                        ></v-select>
                      </span>
                      <span v-else>
                        <v-select
                          v-model="payment_item"
                          label="支払条件"
                          :items=payment_items
                          variant="outlined"
                          disabled
                        ></v-select>
                      </span>
                      <span v-if="status==10">
                        <v-text-field
                          v-model="work_place"
                          label="作業場所"
                          clear-icon="mdi-close-circle"
                          clearable
                        ></v-text-field>
                      </span>
                      <span v-else>
                        <v-text-field
                          v-model="work_place"
                          label="作業場所"
                          variant="outlined"
                          disabled
                        ></v-text-field>
                      </span>
                      <span v-if="status==10">
                        <v-text-field
                          v-model="work_person"
                          label="作業担当者"
                          clear-icon="mdi-close-circle"
                          clearable
                        ></v-text-field>
                      </span>
                      <span v-else>
                        <v-text-field
                          v-model="work_person"
                          label="作業担当者"
                          variant="outlined"
                          disabled
                        ></v-text-field>
                      </span>
                      <span v-if="status==10">
                        <v-text-field
                          v-model="business_manager"
                          label="業務責任者"
                          clear-icon="mdi-close-circle"
                          clearable
                        ></v-text-field>
                      </span>
                      <span v-else>
                        <v-text-field
                          v-model="business_manager"
                          label="業務責任者"
                          variant="outlined"
                          disabled
                        ></v-text-field>
                      </span>
                    </v-col>
                    <h3>内訳</h3><br>
                    <v-col cols="12" sm="12" md="12" lg="12">
                      <v-container>
                        <div v-for="(form, index) in forms" :key="index">
                          <v-row justify="center">
                            <v-col cols="12" sm="12" md="3" lg="3">
                              <span v-if="status==10">
                                <v-text-field
                                  v-model="forms[index].name"
                                  label="名称"
                                  placeholder="名称"
                                  required
                                  counter
                                  maxlength="40"
                                  clear-icon="mdi-close-circle"
                                  clearable
                                ></v-text-field>
                              </span>
                              <span v-else>
                                <v-text-field
                                  v-model="forms[index].name"
                                  label="名称"
                                  placeholder="名称"
                                  required
                                  counter
                                  maxlength="40"
                                  variant="outlined"
                                  disabled
                                ></v-text-field>
                              </span>
                            </v-col>
                            <v-col cols="12" sm="12" md="3" lg="3">
                              <span v-if="status==10">
                                <v-text-field
                                  v-model="forms[index].way"
                                  label="仕様"
                                  placeholder="仕様"
                                  counter
                                  maxlength="30"
                                  clear-icon="mdi-close-circle"
                                  clearable
                                ></v-text-field>
                              </span>
                              <span v-else>
                                <v-text-field
                                  v-model="forms[index].way"
                                  label="仕様"
                                  placeholder="仕様"
                                  counter
                                  maxlength="30"
                                  variant="outlined"
                                  disabled
                                ></v-text-field>
                              </span>
                            </v-col>
                            <v-col cols="6" sm="6" md="2" lg="2">
                              <span v-if="status==10">
                                <v-text-field
                                  v-model="forms[index].quantity"
                                  label="数量"
                                  placeholder="数量"
                                  type="number"
                                  max="999999999999"
                                  min="1"
                                  clear-icon="mdi-close-circle"
                                  clearable
                                ></v-text-field>
                              </span>
                              <span v-else>
                                <v-text-field
                                  v-model="forms[index].quantity"
                                  label="数量"
                                  placeholder="数量"
                                  type="number"
                                  max="999999999999"
                                  min="1"
                                  variant="outlined"
                                  disabled
                                ></v-text-field>
                              </span>
                            </v-col>
                            <v-col cols="6" sm="6" md="1" lg="1">
                              <span v-if="status==10">
                                <v-text-field
                                  v-model="forms[index].unit"
                                  label="単位"
                                  placeholder="単位"
                                ></v-text-field>
                              </span>
                              <span v-else>
                                <v-text-field
                                  v-model="forms[index].unit"
                                  label="単位"
                                  placeholder="単位"
                                  variant="outlined"
                                  disabled
                                ></v-text-field>
                              </span>
                            </v-col>
                            <v-col cols="12" sm="12" md="2" lg="2">
                              <span v-if="status==10">
                                <v-text-field
                                  v-model="forms[index].unit_price"
                                  label="単価"
                                  placeholder="単価"
                                  type="number"
                                  max="999999999999"
                                  min="1"
                                  suffix="円"
                                  clear-icon="mdi-close-circle"
                                  clearable
                                ></v-text-field>
                              </span>
                              <span v-else>
                                <v-text-field
                                  v-model="forms[index].unit_price"
                                  label="単価"
                                  placeholder="単価"
                                  type="number"
                                  max="999999999999"
                                  min="1"
                                  suffix="円"
                                  variant="outlined"
                                  disabled
                                ></v-text-field>
                              </span>
                            </v-col>
                            <v-col cols="12" sm="12" md="1" lg="1">
                              <span v-if="status==10">
                                <v-btn
                                  dark
                                  small
                                  color="grey"
                                  class="ma-2"
                                  @click="deleteForm(index)"
                                >
                                  <v-icon dark>mdi-minus</v-icon>
                                </v-btn>
                              </span>
                            </v-col>
                          </v-row>
                        </div>
                      </v-container>
                      <v-row justify="center">
                        <span v-if="status==10">
                          <v-btn
                            dark
                            small
                            color="grey"
                            class="ma-2"
                            @click="addForm()"
                          >
                            <v-icon dark>mdi-plus</v-icon>
                          </v-btn>
                        </span>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="9" lg="9">
                          <span v-if="status==10">
                            <v-textarea
                              v-model="biko"
                              label="備考"
                              rows="10"
                              row-height="15"
                            ></v-textarea>
                          </span>
                          <span v-else>
                            <v-textarea
                              v-model="biko"
                              label="備考"
                              rows="10"
                              row-height="15"
                              variant="outlined"
                              disabled
                            ></v-textarea>
                          </span>
                        </v-col>
                        <v-col cols="12" sm="12" md="3" lg="3">
                          <v-row>
                            <v-col cols="12" sm="12" md="6" lg="6">
                              小計：
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6">
                              {{totalprice}}円
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="12" md="12" lg="12">
                              <span v-if="status==10">
                                <v-text-field
                                  v-model="nebiki"
                                  label="出精値引き額"
                                  type="number"
                                  max="999999999999"
                                  min="1"
                                  placeholder="0"
                                  suffix="円"
                                  clear-icon="mdi-close-circle"
                                  clearable
                                ></v-text-field>
                              </span>
                              <span v-else>
                                <v-text-field
                                  v-model="nebiki"
                                  label="出精値引き額"
                                  type="number"
                                  max="999999999999"
                                  min="1"
                                  placeholder="0"
                                  suffix="円"
                                  variant="outlined"
                                  disabled
                                ></v-text-field>
                              </span>
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="12" md="6" lg="6">
                              御見積り額：
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6">
                              {{total}}円
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="12" md="6" lg="6">
                              消費税：
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6">
                              {{totaltax}}円
                            </v-col>
                          </v-row>
                          <v-row>
                            <v-col cols="12" sm="12" md="6" lg="6">
                              &emsp;合計：
                            </v-col>
                            <v-col cols="12" sm="12" md="6" lg="6">
                              {{totaltaxprice}}円
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-container>
                </span>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        </v-form>
      </v-main>
    </v-layout>
  </v-card>

  <v-dialog
    v-model="modal"
    width="auto"
  >
    <v-card>
      <v-card-text>
        処理者として複数設定されています。<br>処理者を選択してください。
      </v-card-text>
      <v-card-actions v-for="wait_wf in wait_wf_info_fill.value" :key="wait_wf">
        <v-btn color="primary" block @click="pro_user(wait_wf);modal = false">{{wait_wf.role_name}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform .2s ease-in-out;
}

.button-outline {
  background-color: #0a5d8a;
  width: 70px;
  height: 30px;
  color: #FFFFFF;
  font-size: 10px;
  border-radius: 10px;
}

.drop_area {
  color: gray;
  font-weight: bold;
  font-size: 1.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 300px;
  border: 5px solid gray;
  border-radius: 15px;
}

.enter {
  border: 10px dotted powderblue;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.flex {
  display: flex;
  align-items: center;
}

.flex-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5em;
  font-size: 10px;
}

.delete-mark {
  position: absolute;
  top: -14px;
  right: -10px;
  font-size: 20px;
}

.box_head{
  width:100px;
  border: solid 3px #000000;
}

.box_sub{
  width:100px;
  border: solid 1px #000000;
}

.box_line{
  background-color:gray;
  width:2px;
  height:50px;
  margin:auto;
}

.box_line_right{
  background-color:gray;
  width:2px;
  height:50px;
  margin:auto;
}

</style>

<script setup>
import { ref, reactive, onMounted, computed, inject } from 'vue';
import { useStore } from 'vuex'
import { useRouter, useRoute } from "vue-router"
import axios from 'axios';
import { Core as YubinBangoCore } from "yubinbango-core2";
import workflow from '@/components/modules/workflow.vue';
import dayjs from "dayjs";
dayjs.locale("ja");

const dt = new Date();
const y = dt.getFullYear();
const m = ("00" + (dt.getMonth()+1)).slice(-2);
const d = ("00" + dt.getDate()).slice(-2);
const today = y + "-" + m + "-" + d;


const router = useRouter()
const route = useRoute()
const store = useStore()

const drawer = ref(true)
const rail = ref(true)
const dialog_attached = ref(false)
const dialog_wf = ref(false)
const dialog_wf_pro = ref(false)


const pankuzu = reactive([
  {title: 'ホーム', disabled: false, href: '../home',},
  {title: '見積管理', disabled: true,},
])

const dialog = ref(false)
const payment_items = reactive(['California', 'Colorado', 'Florida'])
const Posts= reactive([])

const customer_columns = reactive([
  {label: '処理', field: 'action', html: true,},
  {label: 'ID', field: 'id', type: 'number', filterOptions: {enabled: true,}},
  {label: '顧客名', field: 'name', filterOptions: {enabled: true,}},
  {label: '都道府県', field: 'prefectures', filterOptions: {enabled: true,}},
  {label: '状況', field: 'status', filterOptions: {enabled: true,}},
  {label: '見積', field: 'estimatelink', html: true,},
  {label: '契約中', field: 'contractlink', html: true,},
  {label: '過去契約', field: 'oldcontractlink', html: true,},
])

//エラーチェック
import { useField, useForm } from 'vee-validate'
const { handleSubmit } = useForm({
  validationSchema: {
    customer (value) {
      if (value?.length >= 1) return true
      return '顧客が選択されておりません。'
    },
    date_of_issue (value) {
      if (value?.length == 10) return true
      return '発行日が正しく入力されておりません。'
    },
  }
})
const submit = handleSubmit(values => {
  console.log(JSON.stringify(values, null, 2))
  estimate_upd('20')
})
const customer = useField('customer')
const date_of_issue = useField('date_of_issue')
//const date_of_issue = ref("")
//const customer = ref("")

const header = {
   headers: { Authorization: `JWT ` + store.state.token }
}

const header_tenpu = {
  headers: { Authorization: `JWT ` + store.state.token,
            'content-type': 'multipart/form-data'}
}

const login_id = store.state.login_id
const company_id = store.state.company_id
const estimate_id = route.params.estimate_id

function logout() {
  axios
    .get("http://127.0.0.1:8000/api/auth/logout/", header)
    .then(response => (logout.value = response.data))
    .catch(error => (this.error = error))
  store.commit("setToken", "")
  store.commit("setlogin", "")
  router.push({ path: "/login"})
}

const postcode = ref("")
const prefectures = ref("")
const municipalities = ref("")
const address1 = ref("")
function searchAddress (postcode) {
  if (!postcode.match(/^\d{3}-?\d{4}$/)) { //入力された値をチェック
    return false
  }
  new YubinBangoCore(this.postcode, (value) => {
    prefectures.value = value.region; // 都道府県 IDを取得したい時はregion_idと記述
    municipalities.value = value.locality; // 市区町村
    address1.value += value.street; // 町域
  });
}

const contract_info = reactive([])
const contract_flg = ref("")
function searchcontract(contract_id) {
  if (contract_id != "") {
    const contract_count = contract_info.value.data.filter(contract => {
      return String(contract.contract_id) == String(contract_id)
    })
    if (contract_count.length >= 1) {
      contract_flg.value = "Y"
    } else {
      contract_flg.value = "N"
    }
  } else {
    contract_flg.value = "A"
  }
}

const customer_rows = reactive([]);
function customer_data (customer_info) {
  customer_info.data.forEach(element =>
    customer_rows.push({
      id: String(element["id"]),
      name: String(element["name"]).replace(null, ''),
      postcode: String(element["postcode"]).replace(null, ''),
      prefectures: String(element["prefectures"]).replace(null, ''),
      municipalities: String(element["municipalities"]).replace(null, ''),
      address1: String(element["address1"]).replace(null, ''),
      address2: String(element["address2"]).replace(null, ''),
      action: '選択',
      estimatelink: 'リンク',
      contractlink: 'リンク',
      oldcontractlink: 'リンク',
    })
  )
}

const customer_tantou_items = reactive([]);
const customer_id = ref("")
const address2 = ref("")
function cus_sel(customer_i,name,post_c,pref,municip,addr1,addr2) {
  customer_id.value = customer_i
  customer.value.value = name
  postcode.value = post_c
  prefectures.value = pref
  municipalities.value = municip
  address1.value = addr1
  address2.value = addr2
  Promise.all(
    [axios.get("http://127.0.0.1:8000/production/login_tantou/" + customer_i + "/", header),
    ]
  )
  .then(([tantou_info]) => {
    customer_tantou_items.value = []
    tantou_info.data.forEach(element =>
      customer_tantou_items.push(String(element["tantou"]).replace(null, ''))
    )
  })
}

const pro_id = ref("")
const pro_status = ref("")
const pro_hierarchy_parent = ref("")
const role_name = ref("")
function pro_user(stat){
  pro_id.value = stat.id
  pro_status.value = stat.status
  pro_hierarchy_parent.value = stat.hierarchy_parent
  role_name.value = stat.role_name
}

function format(date) {
  date = dayjs(date).format("YYYY/MM/DD HH:mm:ss");
  return date
}


function cus_id(customer_i) {
  customer_id.value = customer_i
  Promise.all(
    [axios.get("http://127.0.0.1:8000/production/login_tantou/" + customer_i + "/", header),
     axios.get("http://127.0.0.1:8000/production/customer/upd/" + customer_i + "/", header),
    ]
  )
  .then(([tantou_info,customer_info]) => {
    tantou_info.value = tantou_info.data
    customer_info.value = customer_info.data
    customer.value.value = customer_info.data.name
    customer_tantou_items.value = []
    tantou_info.data.forEach(element =>
      customer_tantou_items.push(String(element["tantou"]).replace(null, ''))
    )
  })
}




const customer_tantou = ref("")
const subject = ref("")
const payment_item = ref("")
const work_place = ref("")
const work_person = ref("")
const business_manager = ref("")
const contract_id = ref("")
const biko = ref("")
const period = ref("")
const swal = inject('$swal')





//フォーム追加・削除
const forms = reactive([]);
function addForm() {
  forms.push({
    name: "",
    way: "",
    quantity: "",
    unit: "",
    unit_price: "",
    price: "",
  })
}
function deleteForm(index) {
  forms.splice(index, 1);
}



//処理
function estimate_upd(stat) {
  if (contract_flg.value == "N") {
    swal.fire('契約IDが登録情報に無いため処理を停止しました!', '', 'error')
  } else {
    swal({
      title: 'データの登録確認',
      text: '入力内容をサーバへ送信してもよろしいですか?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '登録する',
      cancelButtonText: 'キャンセル',
    }).then(result => {
      if (result.value) {
        // 登録処理
        const estimate_data = {
          company: company_id,
          customer: customer_id.value,
          status: stat,
          customer_tantou: customer_tantou.value,
          postcode: postcode.value,
          prefectures: prefectures.value,
          municipalities: municipalities.value,
          address1: address1.value,
          address2: address2.value,
          subject: subject.value,
          payment_item: payment_item.value,
          work_place: work_place.value,
          work_person: work_person.value,
          business_manager: business_manager.value,
          contract_id: contract_id.value,
          totalprice: totalprice.value,
          total: total.value,
          totaltax: totaltax.value,
          tax_district: 10,
          totaltaxprice: totaltaxprice.value,
          biko: biko.value,
          create_date: today,
          created_pg: "estimate_create",
          created_user: login_id,
          uploaded_pg: "estimate_create",
          uploaded_user: login_id,
        }
        if (date_of_issue.value != '') {
          estimate_data.date_of_issue = date_of_issue.value.value
        }
        if (period.value != '') {
          estimate_data.period = period.value
        }
        if (nebiki.value != '') {
          estimate_data.nebiki = nebiki.value
        } else {
          estimate_data.nebiki = 0
        }
        axios
          .put("http://127.0.0.1:8000/production/estimate/upd/" + estimate_id + "/", estimate_data, header)
          .then(response => {
            var estimate_updres = response.data;
            estimate_updres.value = estimate_updres
            forms.forEach(function(element){
              let price = element.quantity * element.unit_price
              let tax = Math.floor(Number(element.quantity * element.unit_price * 0.1))
              let totalprice = Math.floor(Number(element.quantity * element.unit_price + element.quantity * element.unit_price * 0.1))
              const estimate_det_data = {
                name: element.name,
                way: element.way,
                quantity: element.quantity,
                unit: element.unit,
                unit_price: element.unit_price,
                price: price,
                tax: tax,
                tax_district: 10,
                totalprice: totalprice,
                estimate: estimate_id,
                created_pg: "estimate_create",
                created_user: login_id,
                uploaded_pg: "estimate_create",
                uploaded_user: login_id,
              }
              axios
                .put("http://127.0.0.1:8000/production/estimate_breakdown/upd/" + element.breakdown_id + "/", estimate_det_data, header)
                .then(response => {
                  var estimate_updres = response.data;
                  estimate_updres.value = estimate_updres
                })
            })
          axios
            .get("http://127.0.0.1:8000/production/wait_wf_setting/" + company_id + "/" + login_id + "/1/" + estimate_id + "/", header)
            .then(response => {
              var wait_wf_updres = response.data;
              const wait_wf_info_t = wait_wf_updres.filter(wait_wf => {
                return wait_wf.hierarchy_parent == 10
              })
              pro_user(wait_wf_info_t[0])
            })
          })
          .catch(error => (this.error = error))
        if (stat==10) {
          swal.fire('一時保存しました。!', '', 'success')
        } else {
          swal.fire('登録完了しました。!', '', 'success')
          status.value = 20
          router.push('/estimate/' + estimate_id)
        }
      }
    })

  }
}

function estimate_edit (stat) {
  const estimate_data = {
    company: company_id,
    customer: customer_id.value,
    status: stat,
    uploaded_pg: "estimate_edit",
    uploaded_user: login_id,
  }
  axios
    .put("http://127.0.0.1:8000/production/estimate/upd/" + estimate_id + "/", estimate_data, header)
    .then(response => {
      var estimate_updres = response.data;
      estimate_updres.value = estimate_updres
      status.value = stat
    })
  if (stat==99) {
    router.push('/home')
  }
}

function estimate_copy () {
  const estimate_data = {
    company: company_id,
    customer: customer_id.value,
    status: 10,
    customer_tantou: customer_tantou.value,
    postcode: postcode.value,
    prefectures: prefectures.value,
    municipalities: municipalities.value,
    address1: address1.value,
    address2: address2.value,
    subject: subject.value,
    payment_item: payment_item.value,
    work_place: work_place.value,
    work_person: work_person.value,
    business_manager: business_manager.value,
    contract_id: contract_id.value,
    totalprice: totalprice.value,
    total: total.value,
    totaltax: totaltax.value,
    tax_district: 10,
    totaltaxprice: totaltaxprice.value,
    biko: biko,
    created_pg: "estimate_create",
    created_user: login_id,
    uploaded_pg: "estimate_create",
    uploaded_user: login_id,
  }
  if (date_of_issue.value != '') {
    estimate_data.date_of_issue = date_of_issue.value.value
  }
  if (period.value != '') {
    estimate_data.period = period.value
  }
  if (nebiki.value != '') {
    estimate_data.nebiki = nebiki.value
  } else {
    estimate_data.nebiki = 0
  }
  axios
    .post("http://127.0.0.1:8000/production/estimate/create/", estimate_data, header)
    .then(response => {
      var estimate_updres = response.data;
      estimate_updres.value = estimate_updres
      var estimate_id = response.data.id
      var branch_count = 1
      forms.forEach(function(element){
        let price = element.quantity * element.unit_price
        let tax = Math.floor(Number(element.quantity * element.unit_price * 0.1))
        let totalprice = Math.floor(Number(element.quantity * element.unit_price + element.quantity * element.unit_price * 0.1))
        const estimate_det_data = {
          branch: branch_count,
          name: element.name,
          way: element.way,
          quantity: element.quantity,
          unit: element.unit,
          unit_price: element.unit_price,
          price: price,
          tax: tax,
          tax_district: 10,
          totalprice: totalprice,
          estimate: estimate_id,
          create_date: today,
          created_pg: "estimate_create",
          created_user: login_id,
          uploaded_pg: "estimate_create",
          uploaded_user: login_id,
        }
        axios
          .post("http://127.0.0.1:8000/production/estimate_breakdown/create/", estimate_det_data, header)
          .then(response => {
            var estimate_updres = response.data;
            estimate_updres.value = estimate_updres
          })
        branch_count = branch_count + 1
      })
      router.push('/estimate/' + estimate_id)
    })
    .catch(error => (this.error = error))
    status.value = 10
    swal.fire('コピーが完了しました。!', '', 'success')
}

const isEnter = ref("")
const files = reactive([]);
function dragEnter() {
  isEnter.value = true;
}
function dragLeave() {
  isEnter.value = false;
}
function dropFile() {
  files.push(...event.dataTransfer.files)
  isEnter.value = false;
}
function deleteFile(index) {
  files.splice(index, 1)
}

const content = ref("")
function sendFile() {
  const content_data = {
    company: company_id,
    no: estimate_id,
    role_name: role_name.value,
    content: content.value,
  }
  axios
    .post("http://127.0.0.1:8000/production/wf_content/" + login_id + "/1/" + estimate_id + "/", content_data, header)
    .then(response => {
      var estimate_updres = response.data;
      estimate_updres.value = estimate_updres
    })
  files.forEach(file => {
    let form = new FormData()
    form.append('file', file)
    axios
      .post("http://127.0.0.1:8000/production/wf_attached/" + login_id + "/1/" + estimate_id + "/", form, header_tenpu)
      .then(response => {
        attach_files.value = JSON.parse(response.data);
      })
      .catch(error => (this.error = error))
  })
  axios
    .get("http://127.0.0.1:8000/production/wf_attached_get/" + login_id + "/1/" + estimate_id + "/", header)
    .then(response => {
      attach_files.value = JSON.parse(response.data);
      files.value = []
      //router.go({path: router.currentRoute.path, force: true})
    })
    .catch(error => (this.error = error))
}

function del_send_File(attach_f,index){
  attach_files.value.splice(index, 1)
  const attached_data = {
    company: company_id,
    status: 2,
    uploaded_pg: "del_wf_attached",
    uploaded_user: login_id,
  }
  axios
    .put("http://127.0.0.1:8000/production/wf_attached_del/" + attach_f + "/", attached_data, header)
    .then(response => {
      var attached_updres = response.data;
      attached_updres.value = attached_updres
    })
}
import saveAs from "file-saver";
function download_File(attach_fi,attach){
  axios({
    url: 'http://localhost:8000/media/' + attach, // File URL Goes Here
    method: 'GET',
    responseType: 'blob',
  }).then((res) => {
      let mineType = res.headers["content-type"];
      const name = res.headers["content-disposition"];
      const blob = new Blob([res.data], { type: mineType });
      saveAs(blob, name);
  });

}


function estimate_approval(stat){
  let modal_title = ""
  let modal_text = ""
  let modal_confirmbtn = ""
  if (stat==10) {
    modal_title = '回覧実行確認'
    modal_text = '回覧実行してもよろしいですか?'
    modal_confirmbtn = '回覧実行'
  } else if(stat==20) {
    modal_title = '承認実行確認'
    modal_text = '承認してもよろしいですか?'
    modal_confirmbtn = '承認する'
  } else if(stat==30) {
    modal_title = '事前承認実行確認'
    modal_text = '事前承認してもよろしいですか?'
    modal_confirmbtn = '承認する'
  } else if(stat==40) {
    modal_title = '代理承認実行確認'
    modal_text = '代理承認してもよろしいですか?'
    modal_confirmbtn = '承認する'
  } else if(stat==50) {
    modal_title = '事後承認実行確認'
    modal_text = '代理承認されております。事後承認してもよろしいですか?'
    modal_confirmbtn = '承認する'
  } else if(stat==90) {
    modal_title = '最終承認実行確認'
    modal_text = '最終承認してもよろしいですか?'
    modal_confirmbtn = '承認する'
  }
  swal({
    title: modal_title,
    text: modal_text,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: modal_confirmbtn,
    cancelButtonText: 'キャンセル',
    input: 'textarea',
    inputLabel: '回覧メッセージ',
    inputPlaceholder: '',
    inputAttributes: {
      'aria-label': ''
    },
  }).then(result => {
    if (result.isConfirmed) {
      let role_name = ""
      if (stat == 10) {
        role_name = "回覧実行"
        const estimate_data = {
          company: company_id,
          customer: customer_id.value,
          draft_date: today,
          status: 30,
        }
        axios
          .put("http://127.0.0.1:8000/production/estimate/upd/" + estimate_id + "/", estimate_data, header)
          .then(response => {
            var estimate_updres = response.data;
            estimate_updres.value = estimate_updres
          })
      } else if (stat == 20) {
        role_name = "承認"
      } else if (stat == 30) {
        role_name = "事前承認"
        //事前承認のマーク必要
      } else if (stat == 40) {
        role_name = "代理承認"
        //代理承認のマーク必要
      } else if (stat == 50) {
        //代理承認の事後承認
        role_name = "事後承認"
      } else if (stat == 90) {
        role_name = "最終承認"
        const estimate_data = {
          company: company_id,
          customer: customer_id.value,
          approval_date: today,
          status: 90,
          approval_user: login_id,
        }
        axios
          .put("http://127.0.0.1:8000/production/estimate/upd/" + estimate_id + "/", estimate_data, header)
          .then(response => {
            var estimate_updres = response.data;
            estimate_updres.value = estimate_updres
          })
      }
      axios
        .get("http://127.0.0.1:8000/production/wf_upd/" + pro_id.value + "/" + stat + "/", header)
        .then(response => {
          var estimate_updres = response.data;
          estimate_updres.value = estimate_updres
        })
      const wf_pro_data = {
        company: company_id,
        kbn: 1,
        no: estimate_id,
        role_name: role_name,
        user_id: login_id,
        group_id: "",
        user_name: user_name.value,
        status: 90,
        information: result.value,
        created_pg: "estimate_wf_pro_ins",
        created_user: login_id,
        uploaded_pg: "estimate_wf_pro_ins",
        uploaded_user: login_id,
      }
      axios
        .post("http://127.0.0.1:8000/production/wf_pro_ins/", wf_pro_data, header)
        .then(response => {
          var estimate_updres = response.data;
          estimate_updres.value = estimate_updres
        })
    status.value = 90
    router.go({path: router.currentRoute.path, force: true})
    }
  })
}

function estimate_return(stat) {
  let modal_title = ""
  let modal_text = ""
  let modal_confirmbtn = ""
  if (stat==10) {
    modal_title = '差戻実行確認'
    modal_text = '差戻ししてもよろしいですか?'
    modal_confirmbtn = '差戻する'
  } else if(stat==20) {
    modal_title = '取下実行確認'
    modal_text = '取下げしてもよろしいですか?'
    modal_confirmbtn = '取下する'
  }
   swal({
    title: modal_title,
    text: modal_text,
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: modal_confirmbtn,
    cancelButtonText: 'キャンセル',
    input: 'textarea',
    inputLabel: '回覧メッセージ',
    inputPlaceholder: '',
    inputAttributes: {
      'aria-label': ''
    },
  }).then(result => {
    if (result.isConfirmed) {
      let role_name = ""
      let status_l = 20
      if (stat == 10) {
        status_l = 22
        role_name = "差戻"
      } else if(stat==20) {
      status_l = 21
        role_name = "取下"
      }
      const estimate_data = {
        company: company_id,
        customer: customer_id.value,
        status: status_l,
      }
      axios
        .put("http://127.0.0.1:8000/production/estimate/upd/" + estimate_id + "/", estimate_data, header)
        .then(response => {
          var estimate_updres = response.data;
          estimate_updres.value = estimate_updres
        })
      axios
        .get("http://127.0.0.1:8000/production/wf_return/1/" + estimate_id + "/", header)
        .then(response => {
          var estimate_updres = response.data;
          estimate_updres.value = estimate_updres
        })
      const wf_pro_data = {
        company: company_id,
        kbn: 1,
        no: estimate_id,
        role_name: role_name,
        user_id: login_id,
        group_id: "",
        user_name: user_name.value,
        status: stat,
        information: result.value,
        created_pg: "estimate_wf_pro_ins",
        created_user: login_id,
        uploaded_pg: "estimate_wf_pro_ins",
        uploaded_user: login_id,
      }
      axios
        .post("http://127.0.0.1:8000/production/wf_pro_ins/", wf_pro_data, header)
        .then(response => {
          var estimate_updres = response.data;
          estimate_updres.value = estimate_updres
        })
    status.value = 90
    router.push('/home')
    }
  })
}




//画面遷移
function estimatelink(ind) {
  router.push('/estimate/' + ind)
}
function contractlink(ind) {
  router.push('/contract/' + ind)
}
function oldcontractlink(ind) {
  router.push('/oldcontract/' + ind)
}


const nebiki = ref(0)
const totalprice = computed (() => {
  return forms.reduce((sum, i) => sum + Number(i.quantity * i.unit_price), 0)
})
const total = computed (() => {
  return Math.floor(Number(totalprice.value - nebiki.value))
})
const totaltax = computed (() => {
  return Math.floor(total.value * 0.1)
})
const totaltaxprice = computed (() => {
  return Math.floor(Number(total.value + totaltax.value))
})

const modal = ref(false)
const status = ref("")
const user_name = ref("")
const attach_files = reactive([]);
const wait_wf_info_fill = reactive([]);
const wf_pro_info = reactive([]);
onMounted(() => {
  store.commit("setkeiyaku", 1)
  store.commit("setkeiyakuid", route.params.estimate_id)
  Promise.all(
    [axios.get("http://127.0.0.1:8000/production/login_info/" + login_id + "/", header),
     axios.get("http://127.0.0.1:8000/production/login_company/" + login_id + "/", header),
     axios.get("http://127.0.0.1:8000/production/login_customer/" + company_id + "/", header),
     axios.get("http://127.0.0.1:8000/production/estimate/upd/" + estimate_id + "/", header),
     axios.get("http://127.0.0.1:8000/production/estimate_breakdown/get/" + estimate_id + "/", header),
     axios.get("http://127.0.0.1:8000/production/wait_wf/" + login_id + "/1/" + estimate_id + "/", header),
     axios.get("http://127.0.0.1:8000/production/wait_wf_pro/1/" + estimate_id + "/", header),
     axios.get("http://127.0.0.1:8000/production/wf_content_get/" + login_id + "/1/" + estimate_id + "/", header),
     axios.get("http://127.0.0.1:8000/production/wf_attached_get/" + login_id + "/1/" + estimate_id + "/", header),
     axios.get("http://127.0.0.1:8000/production/contract/search/" + company_id + "/", header)
    ]
  )
  .then(([user_info, login_company, customer_info, estimate_info,estimate_breakdown_info,wait_wf_info,wf_pro_inf,content_info,wf_attach_info,contract_inf]) => {
    let staff = user_info.data[0]["is_staff"]
    store.commit("setcompany", login_company.data[0]["company"])
    if ( staff == 0 ) {
      user_name.value = user_info.data[0]["last_name"] + " " + user_info.data[0]["first_name"]
      contract_info.value = contract_inf
      customer_info.value = customer_info.data
      estimate_info.value = estimate_info.data
      wf_pro_info.value = wf_pro_inf.data
      attach_files.value = JSON.parse(wf_attach_info.data)
      content_info.value = content_info.data
      if (content_info.data.length==1) {
        content.value = content_info.data[0].content
      }
      status.value = estimate_info.data.status
      customer_tantou.value = estimate_info.data.customer_tantou
      postcode.value = estimate_info.data.postcode
      prefectures.value = estimate_info.data.prefectures
      municipalities.value = estimate_info.data.municipalities
      address1.value = estimate_info.data.address1
      address2.value = estimate_info.data.address2
      date_of_issue.value.value = estimate_info.data.date_of_issue
      subject.value = estimate_info.data.subject
      period.value = estimate_info.data.period
      payment_item.value = estimate_info.data.payment_item
      work_place.value = estimate_info.data.work_place
      work_person.value = estimate_info.data.work_person
      business_manager.value = estimate_info.data.business_manager
      totalprice.value = estimate_info.data.totalprice
      nebiki.value = estimate_info.data.nebiki
      total.value = estimate_info.data.total
      totaltax.value = estimate_info.data.totaltax
      totaltaxprice.value = estimate_info.data.totaltaxprice
      biko.value = estimate_info.data.biko
      contract_id.value = estimate_info.data.contract_id
      cus_id(estimate_info.data.customer)
      customer_data(customer_info)

      estimate_breakdown_info.data.forEach(element =>
        forms.push({
          breakdown_id: String(element["id"]),
          name: String(element["name"]),
          way: String(element["way"]),
          quantity: element["quantity"],
          unit: String(element["unit"]),
          unit_price: element["unit_price"],
          price: element["price"],
        })
      )

      let customer_rows = []
      customer_info.data.forEach(element =>
        customer_rows.push({
          id: String(element["id"]),
          name: String(element["name"]).replace(null, ''),
          postcode: String(element["postcode"]).replace(null, ''),
          prefectures: String(element["prefectures"]).replace(null, ''),
          municipalities: String(element["municipalities"]).replace(null, ''),
          address1: String(element["address1"]).replace(null, ''),
          address2: String(element["address2"]).replace(null, ''),
          action: '選択',
          estimatelink: 'リンク',
          contractlink: 'リンク',
          oldcontractlink: 'リンク',
        })
      )
      customer_rows.value = customer_rows
      //処理者特定
      const wait_wf_info_fil = wait_wf_info.data.filter(wait_wf => {
        return wait_wf.status <= 80
      })

      if (wait_wf_info_fil.length >= 2) {
        if (estimate_info.data.status <= 29) {
          const wait_wf_info_t = wait_wf_info_fil.filter(wait_wf => {
            return wait_wf.hierarchy_parent == 10
          })
          pro_user(wait_wf_info_t[0])
        } else {
          wait_wf_info_fill.value = wait_wf_info_fil
          modal.value = true;
        }
      } else {
        pro_user(wait_wf_info_fil[0])
      }


    } else {
      swal.fire('権限がありません。!', '', 'error')
      router.push('/login')
    }
  })
})

</script>
