import { createRouter, createWebHistory } from 'vue-router'
import { trackRouter } from "vue-gtag-next";
import Login from '@/components/pages/login'
import Home from '@/components/pages/home'
import Customer from '@/components/pages/customer'
import Customer_detail from '@/components/pages/customer_detail'
import Customer_tantou_detail from '@/components/pages/customer_tantou_detail'
import Looking_personnel from '@/components/pages/looking_personnel'
import Looking_personnel_detail from '@/components/pages/looking_personnel_detail'
import Looking_deals from '@/components/pages/looking_deals'
import Looking_deals_detail from '@/components/pages/looking_deals_detail'
import Estimate from '@/components/pages/estimate'
import Estimate_detail from '@/components/pages/estimate_detail'
import Order from '@/components/pages/order'
import Order_detail from '@/components/pages/order_detail'
import Hachu from '@/components/pages/hachu'
import Report from '@/components/pages/report'

const routes = [
  {path:'/', name:'Login', component:Login, meta:{title:'ログイン', keywords:"ログイン", desc:'ログイン'} },
  {path:'/home', name:'Home', component:Home, meta:{title:'ホーム', keywords:"ホーム", desc:'ホーム'} },
  {path:'/customer', name:'Customer', component:Customer, meta:{title:'顧客管理', keywords:"顧客管理", desc:'顧客管理'} },
  {path:'/customer/:customer_id', name:'Customer_detail', component:Customer_detail, meta:{title:'顧客詳細', keywords:"顧客詳細", desc:'顧客詳細'} },
  {path:'/customer/tantou/:customer_id/:tantou_id', name:'Customer_tantou_detail', component:Customer_tantou_detail, meta:{title:'担当詳細', keywords:"担当詳細", desc:'担当詳細'} },
  {path:'/Looking_personnel', name:'Looking_personnel', component:Looking_personnel, meta:{title:'SES要員探し', keywords:"SES要員探し", desc:'SES要員探し'} },
  {path:'/Looking_personnel/:publish_id', name:'Looking_personnel_detail', component:Looking_personnel_detail, meta:{title:'SES要員探し詳細', keywords:"SES要員探し詳細", desc:'SES要員探し詳細'} },
  {path:'/Looking_deals', name:'Looking_deals', component:Looking_deals, meta:{title:'SES案件探し', keywords:"SES案件探し", desc:'SES案件探し'} },
  {path:'/Looking_deals/:publish_id', name:'Looking_deals_detail', component:Looking_deals_detail, meta:{title:'SES案件探し詳細', keywords:"SES案件探し詳細", desc:'SES案件探し詳細'} },
  {path:'/estimate', name:'Estimate', component:Estimate, meta:{title:'見積管理', keywords:"見積管理", desc:'見積管理'} },
  {path:'/estimate/:estimate_id', name:'Estimate_detail', component:Estimate_detail, meta:{title:'見積詳細', keywords:"見積詳細", desc:'見積詳細'} },
  {path:'/order', name:'Order', component:Order, meta:{title:'受注管理', keywords:"受注管理", desc:'受注管理'} },
  {path:'/order/:order_id', name:'Order_detail', component:Order_detail, meta:{title:'受注詳細', keywords:"受注詳細", desc:'受注詳細'} },
  {path:'/Hachu', name:'Hachu', component:Hachu, meta:{title:'発注管理', keywords:"発注管理", desc:'発注管理'} },
  {path:'/report', name:'Report', component:Report, meta:{title:'帳票出力', keywords:"帳票出力", desc:'帳票出力'} },
]

const router = new createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        top: 0
      }
    }
  },
  history:  createWebHistory(process.env.BASE_URL),
  routes
})


router.afterEach((to) => {
document.querySelector("meta[name='description']").setAttribute('content', to.meta.desc)
document.querySelector("meta[name='keywords']").setAttribute('content', to.meta.keywords)
document.title = "Nuage | " + to.meta.title
})

trackRouter(router);

export default router

