<template>
  <v-card>
    <v-layout>
      <v-navigation-drawer
        v-model="drawer"
        :rail="rail"
        permanent
        @click="rail = false"
      >
        <v-list-item
          :title=this.user_name
          nav
        >
          <template v-slot:append>
            <v-btn
              variant="text"
              icon="mdi-chevron-left"
              @click.stop="rail = !rail"
            ></v-btn>
          </template>
        </v-list-item>

        <v-divider></v-divider>

        <v-list density="compact" nav>
          <v-list-item prepend-icon="mdi-home-city" title="ホーム" value="home" to="/home"></v-list-item>
          <v-list-item prepend-icon="mdi-account" title="顧客管理" value="customer" to="/customer"></v-list-item>
          <v-list-item prepend-icon="mdi-book-open" title="見積管理" value="estimate" to="/estimate"></v-list-item>
          <v-list-item prepend-icon="mdi-store" title="受注管理" value="order" to="/order"></v-list-item>
          <v-list-item prepend-icon="mdi-cart" title="発注管理" value="hachu" to="/hachu"></v-list-item>
          <v-list-item prepend-icon="mdi-chart-line" title="帳票出力" value="report" to="/report"></v-list-item>
          <v-list-item prepend-icon="mdi-logout" title="ログアウト" value="logout" @click="logout()"></v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-main>
        <v-container>
          <v-breadcrumbs :items="pankuzu">
            <template v-slot:divider>
              <v-icon icon="mdi-chevron-right"></v-icon>
            </template>
          </v-breadcrumbs>
          <v-row justify="center">
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-card>
                <v-tabs
                  v-model="tab"
                  color="deep-purple-accent-4"
                  align-tabs="center"
                >
                  <v-tab :value="1">受注書</v-tab>
                  <v-tab :value="2">処理待ち</v-tab>
                  <v-tab :value="3">検索</v-tab>
                </v-tabs>
                <v-window v-model="tab" class="mb-15">
                  <v-window-item
                    :value="1"
                  >
                  <v-form @submit.prevent="submit">
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12" sm="12" md="12" lg="12">
                          <h1 class="ml-3">受注書作成</h1>
                          <span>
                            <v-btn type="submit" class="ma-3" variant="outlined">登録</v-btn>
                            <v-btn class="ma-3" variant="outlined" @click="order_cre('10')">一時保存</v-btn>
                          </span>
                          <v-container>
                            <v-row justify="center">
                              <v-col cols="10" sm="10" md="5" lg="5">
                                <v-text-field
                                  v-model="customer.value.value"
                                  label="顧客"
                                  variant="outlined"
                                  :error-messages="customer.errorMessage.value"
                                  disabled
                                ></v-text-field>
                              </v-col>
                              <v-col cols="2" sm="2" md="1" lg="1">
                                <v-container fluid>
                                  <v-row>
                                    <v-col cols="2" sm="2" md="1" lg="1">
                                      <v-dialog
                                        v-model="dialog"
                                        fullscreen
                                        :scrim="false"
                                        transition="dialog-bottom-transition"
                                      >
                                        <template v-slot:activator="{ props }">
                                          <v-btn
                                            color="primary"
                                            dark
                                            class="ml-n9 mt-n2"
                                            v-bind="props"
                                            size="large"
                                          >
                                            選択
                                          </v-btn>
                                        </template>
                                        <v-card>
                                          <v-toolbar
                                            dark
                                            color="primary"
                                          >
                                            <v-btn
                                              icon
                                              dark
                                              @click="dialog = false"
                                            >
                                              <v-icon>mdi-close</v-icon>
                                            </v-btn>
                                            <v-toolbar-title>検索条件</v-toolbar-title>
                                            <v-spacer></v-spacer>
                                          </v-toolbar>
                                          <v-title>
                                            検索ヒット件数：{{ Posts.length }}{{ text }}件
                                          </v-title>
                                          <v-container>
                                            <v-row class="mb-12">
                                              <v-container>
                                                <v-row justify="center">
                                                  <v-col cols="12" sm="12" md="12" lg="12">
                                                    <div class="mb-15">
                                                      <vue-good-table
                                                        v-on:selected-rows-change="selectionChanged"
                                                        :columns="customer_columns"
                                                        :rows="customer_rows"
                                                        compactMode
                                                        style-class="vgt-table striped"
                                                        :pagination-options="{
                                                          enabled: true,
                                                          mode: 'records',
                                                          perPage: 100,
                                                          position: 'top',
                                                          perPageDropdown: [10, 30, 50, 100],
                                                          dropdownAllowAll: false,
                                                          nextLabel: '次',
                                                          prevLabel: '前',
                                                          rowsPerPageLabel: '表示数',
                                                          ofLabel: 'の',
                                                          pageLabel: 'ページ', // for 'pages' mode
                                                          allLabel: '全て',
                                                          infoFn: (params) => `${params.firstRecordOnPage} - ${params.lastRecordOnPage}`,
                                                        }"
                                                      >
                                                        <template #table-row="props">
                                                          <span v-if="props.column.field == 'action'">
                                                            <button class="button-outline" @click="cus_sel(props.row.id,props.row.name,props.row.postcode,props.row.prefectures,props.row.municipalities,props.row.address1,props.row.address2); dialog=false">{{props.row.action}}</button>
                                                          </span>
                                                          <span v-if="props.column.field == 'contract_link'">
                                                            <button class="button-outline" @click="contractlink(props.row.id)">{{props.row.contract_link}}</button>
                                                          </span>
                                                        </template>
                                                        <template #emptystate>
                                                          検索結果は0件です
                                                        </template>
                                                      </vue-good-table>
                                                    </div>
                                                  </v-col>
                                                </v-row>
                                              </v-container>
                                            </v-row>
                                          </v-container>
                                        </v-card>
                                      </v-dialog>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-col>
                              <v-col cols="12" sm="12" md="6" lg="6">
                                <v-combobox
                                  v-model="customer_tantou"
                                  label="顧客側担当者"
                                  :items=customer_tantou_items
                                  clear-icon="mdi-close-circle"
                                  clearable
                                ></v-combobox>
                              </v-col>
                              <v-col cols="12" sm="12" md="12" lg="12">
                                <v-text-field
                                  v-model="postcode"
                                  label="郵便番号"
                                  counter
                                  maxlength="7"
                                  clear-icon="mdi-close-circle"
                                  clearable
                                  @change="searchAddress(postcode)"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="6" lg="6">
                                <v-text-field
                                  v-model="prefectures"
                                  label="都道府県"
                                  clear-icon="mdi-close-circle"
                                  clearable
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="6" lg="6">
                                <v-text-field
                                  v-model="municipalities"
                                  label="市区町村"
                                  clear-icon="mdi-close-circle"
                                  clearable
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="6" lg="6">
                                <v-text-field
                                  v-model="address1"
                                  label="住所"
                                  clear-icon="mdi-close-circle"
                                  clearable
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="6" lg="6">
                                <v-text-field
                                  v-model="address2"
                                  label="建物名・ビル名"
                                  clear-icon="mdi-close-circle"
                                  clearable
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="12" lg="12">
                              <small>※契約IDは契約に紐づけたいときに入力してください。（未入力時自動で契約IDが生成されます）
                              紐づかない契約IDを入力された場合はエラーとなります。</small>
                                <v-text-field
                                  v-model="contract_id"
                                  label="契約ID"
                                  clear-icon="mdi-close-circle"
                                  clearable
                                  @change="searchcontract(contract_id)"
                                ></v-text-field>
                                <span class="error_class" v-if="contract_flg=='N'">契約IDに紐づかないIDが入力されています。</span>
                                <v-text-field
                                  v-model="date_of_issue.value.value"
                                  label="発行日"
                                  type="date"
                                  :error-messages="date_of_issue.errorMessage.value"
                                ></v-text-field>
                                <v-text-field
                                  v-model="subject"
                                  label="件名"
                                  maxlength="30"
                                  clear-icon="mdi-close-circle"
                                  clearable
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="6" lg="6">
                                <v-text-field
                                  v-model="cont_start_date.value.value"
                                  label="契約開始日"
                                  type="date"
                                  :error-messages="cont_start_date.errorMessage.value"
                                  clear-icon="mdi-close-circle"
                                  clearable
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="6" lg="6">
                                <v-text-field
                                  v-model="cont_end_date.value.value"
                                  label="契約終了日"
                                  type="date"
                                  :error-messages="cont_end_date.errorMessage.value"
                                  clear-icon="mdi-close-circle"
                                  clearable
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="12" lg="12">
                                <v-text-field
                                  v-model="delivery_date.value.value"
                                  label="納品日"
                                  type="date"
                                  :error-messages="delivery_date.errorMessage.value"
                                  clear-icon="mdi-close-circle"
                                  clearable
                                ></v-text-field>
                                <v-select
                                  v-model="payment_item"
                                  label="支払条件"
                                  :items=payment_items
                                  clear-icon="mdi-close-circle"
                                  clearable
                                ></v-select>
                                <v-text-field
                                  v-model="work_place"
                                  label="作業場所"
                                  clear-icon="mdi-close-circle"
                                  clearable
                                ></v-text-field>
                                <v-text-field
                                  v-model="work_person"
                                  label="作業担当者"
                                  clear-icon="mdi-close-circle"
                                  clearable
                                ></v-text-field>
                                <v-text-field
                                  v-model="business_manager"
                                  label="業務責任者"
                                  clear-icon="mdi-close-circle"
                                  clearable
                                ></v-text-field>
                              </v-col>
                              <h3>内訳</h3><br>
                              <v-col cols="12" sm="12" md="12" lg="12">
                                <v-container>
                                  <div v-for="(form, index) in forms" :key="index">
                                    <v-row justify="center">
                                      <v-col cols="12" sm="12" md="3" lg="3">
                                        <v-text-field
                                          v-model="forms[index].name"
                                          label="名称"
                                          placeholder="名称"
                                          counter
                                          maxlength="40"
                                          clear-icon="mdi-close-circle"
                                          clearable
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="12" md="3" lg="3">
                                        <v-text-field
                                          v-model="forms[index].way"
                                          label="仕様"
                                          placeholder="仕様"
                                          counter
                                          maxlength="30"
                                          clear-icon="mdi-close-circle"
                                          clearable
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="6" sm="6" md="2" lg="2">
                                        <v-text-field
                                          v-model="forms[index].quantity"
                                          label="数量"
                                          placeholder="数量"
                                          type="number"
                                          max="999999999999"
                                          min="1"
                                          clear-icon="mdi-close-circle"
                                          clearable
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="6" sm="6" md="1" lg="1">
                                        <v-text-field
                                          v-model="forms[index].unit"
                                          label="単位"
                                          placeholder="単位"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="12" md="2" lg="2">
                                        <v-text-field
                                          v-model="forms[index].unit_price"
                                          label="単価"
                                          placeholder="単価"
                                          type="number"
                                          max="999999999999"
                                          min="1"
                                          suffix="円"
                                          clear-icon="mdi-close-circle"
                                          clearable
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="12" md="1" lg="1">
                                        <v-btn
                                          dark
                                          small
                                          color="grey"
                                          class="ma-2"
                                          @click="deleteForm(index)"
                                        >
                                          <v-icon dark>mdi-minus</v-icon>
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </div>
                                </v-container>
                                <v-row justify="center">
                                  <v-btn
                                    dark
                                    small
                                    color="grey"
                                    class="ma-2"
                                    @click="addForm()"
                                  >
                                    <v-icon dark>mdi-plus</v-icon>
                                  </v-btn>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" sm="12" md="9" lg="9">
                                    <v-textarea
                                      v-model="biko"
                                      label="備考"
                                      rows="10"
                                      row-height="15"
                                    ></v-textarea>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="3" lg="3">
                                    <v-row>
                                      <v-col cols="12" sm="12" md="6" lg="6">
                                        契約金額：
                                      </v-col>
                                      <v-col cols="12" sm="12" md="6" lg="6">
                                        {{total}}円
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12" sm="12" md="6" lg="6">
                                        消費税：
                                      </v-col>
                                      <v-col cols="12" sm="12" md="6" lg="6">
                                        {{totaltax}}円
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12" sm="12" md="6" lg="6">
                                        &emsp;合計：
                                      </v-col>
                                      <v-col cols="12" sm="12" md="6" lg="6">
                                        {{totaltaxprice}}円
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                  </v-window-item>
                  <v-window-item
                    :value="2"
                  >
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12">
                          <h1 class="ml-3">処理待ち</h1>
                            <v-container>
                              <v-row justify="center">
                                <v-col cols="12" sm="12" md="12" lg="12">
                                  <div class="mb-15">
                                    <vue-good-table
                                      v-on:selected-rows-change="selectionChanged"
                                      :columns="order_columns"
                                      :rows="order_rows"
                                      compactMode
                                      style-class="vgt-table striped"
                                      :pagination-options="{
                                        enabled: true,
                                        mode: 'records',
                                        perPage: 100,
                                        position: 'top',
                                        perPageDropdown: [10, 30, 50, 100],
                                        dropdownAllowAll: false,
                                        nextLabel: '次',
                                        prevLabel: '前',
                                        rowsPerPageLabel: '表示数',
                                        ofLabel: 'の',
                                        pageLabel: 'ページ', // for 'pages' mode
                                        allLabel: '全て',
                                        infoFn: (params) => `${params.firstRecordOnPage} - ${params.lastRecordOnPage}`,
                                      }"
                                    >
                                      <template #table-row="props">
                                        <span v-if="props.column.field == 'action'">
                                          <button class="button-outline" @click="orderlink(props.row.id)">{{props.row.action}}</button>
                                        </span>
                                        <span v-if="props.column.field == 'cont_link'">
                                          <button class="button-outline" @click="order_report(props.row.id)">{{props.row.cont_link}}</button>
                                        </span>
                                        <span v-if="props.column.field == 'attach_link'">
                                          <button class="button-outline">
                                            {{props.row.attach_link}}

                                            <v-overlay
                                              activator="parent"
                                              location-strategy="connected"
                                              scroll-strategy="close"
                                            >
                                              <v-card>
                                                <div>
                                                  <ul class="flex">
                                                    <li class="flex-col" v-for="(attach_f,index) in attach_files.value" :key="index">
                                                      <div v-if="props.row.id == attach_f.fields.no">
                                                        <div style="position: relative;">
                                                          <img class="file_icon" src="/media/documents/production/file.png" @dblclick="download_File(attach_f.pk,attach_f.fields.attach)">
                                                        </div>
                                                        <span>{{ attach_f.fields.role_name }}</span>
                                                      </div>
                                                    </li>
                                                  </ul>
                                                </div>
                                              </v-card>
                                            </v-overlay>
                                          </button>
                                        </span>
                                      </template>
                                      <template #emptystate>
                                        検索結果は0件です
                                      </template>
                                    </vue-good-table>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-container>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-window-item>
                  <v-window-item
                    :value="3"
                  >
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12">
                          <h1 class="ml-3">検索</h1>
                          <v-col cols="5" sm="3" md="2" lg="2">
                            <v-dialog
                              v-model="dialog_search"
                              fullscreen
                              :scrim="false"
                              transition="dialog-bottom-transition"
                            >
                              <template v-slot:activator="{ props }">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="ma-2"
                                  v-bind="props"
                                  size="x-large"
                                >
                                  検索条件
                                </v-btn>
                              </template>
                              <v-card>
                                <v-toolbar
                                  dark
                                  color="primary"
                                >
                                  <v-btn
                                    icon
                                    dark
                                    @click="dialog_search = false"
                                  >
                                    <v-icon>mdi-close</v-icon>
                                  </v-btn>
                                  <v-toolbar-title>検索条件</v-toolbar-title>
                                  <v-spacer></v-spacer>
                                  <v-toolbar-items>
                                    <v-btn
                                      variant="text"
                                      @click="dialog_search = false"
                                    >
                                      閉じる
                                    </v-btn>
                                  </v-toolbar-items>
                                </v-toolbar>
                                <v-title>
                                  検索ヒット件数：{{ search_rows.length }}件
                                </v-title>
                                <v-container>
                                <v-row class="mb-12">
                                  <v-col cols="12" sm="12" md="12" lg="12">
                                    <v-select
                                      v-model="search_status"
                                      :items="status_items"
                                      chips
                                      label="ステータス"
                                      multiple
                                      @change="selected = search_order"
                                    ></v-select>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="5" lg="5">
                                    <v-text-field
                                      v-model="search_contract_id_s"
                                      label="契約ID"
                                      @change="search_order"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="1" lg="1">
                                    <v-btn
                                      dark
                                      small
                                      color="grey"
                                      class="ma-2"
                                    >
                                      <v-icon dark>mdi-minus</v-icon>
                                    </v-btn>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="5" lg="5">
                                    <v-text-field
                                      v-model="search_contract_id_e"
                                      label="契約ID"
                                      @change="search_order"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="12" lg="12">
                                    <v-text-field
                                      v-model="search_create"
                                      label="作成者"
                                      @change="search_order"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="12" lg="12">
                                    <v-text-field
                                      v-model="search_Approval"
                                      label="決裁者"
                                      @change="search_order"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="5" lg="5">
                                    <v-text-field
                                      v-model="search_date_of_issue_s"
                                      label="発行日"
                                      type="date"
                                      @change="search_order"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="1" lg="1">
                                    <v-btn
                                      dark
                                      small
                                      color="grey"
                                      class="ma-2"
                                    >
                                      <v-icon dark>mdi-minus</v-icon>
                                    </v-btn>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="5" lg="5">
                                    <v-text-field
                                      v-model="search_date_of_issue_e"
                                      label="発行日"
                                      type="date"
                                      @change="search_order"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="5" lg="5">
                                    <v-text-field
                                      v-model="search_money_s"
                                      label="見積金額"
                                      type="number"
                                      max="999999999999"
                                      min="1"
                                      suffix="円"
                                      @change="search_order"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="1" lg="1">
                                    <v-btn
                                      dark
                                      small
                                      color="grey"
                                      class="ma-2"
                                    >
                                      <v-icon dark>mdi-minus</v-icon>
                                    </v-btn>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="5" lg="5">
                                    <v-text-field
                                      v-model="search_money_e"
                                      label="見積金額"
                                      type="number"
                                      max="999999999999"
                                      min="1"
                                      suffix="円"
                                      @change="search_order"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="5" lg="5">
                                    <v-text-field
                                      v-model="search_date_of_circulation_s"
                                      label="回覧開始日"
                                      type="date"
                                      @change="search_order"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="1" lg="1">
                                    <v-btn
                                      dark
                                      small
                                      color="grey"
                                      class="ma-2"
                                    >
                                      <v-icon dark>mdi-minus</v-icon>
                                    </v-btn>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="5" lg="5">
                                    <v-text-field
                                      v-model="search_date_of_circulation_e"
                                      label="回覧開始日"
                                      type="date"
                                      @change="search_order"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="5" lg="5">
                                    <v-text-field
                                      v-model="search_approval_date_s"
                                      label="完了日"
                                      type="date"
                                      @change="search_order"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="1" lg="1">
                                    <v-btn
                                      dark
                                      small
                                      color="grey"
                                      class="ma-2"
                                    >
                                      <v-icon dark>mdi-minus</v-icon>
                                    </v-btn>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="5" lg="5">
                                    <v-text-field
                                      v-model="search_approval_date_e"
                                      label="完了日"
                                      type="date"
                                      @change="search_order"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card>
                          </v-dialog>
                        </v-col>
                          <v-container>
                            <v-row justify="center">
                              <v-col cols="12" sm="12" md="12" lg="12">
                                <div class="mb-15">
                                  <vue-good-table
                                    v-on:selected-rows-change="selectionChanged"
                                    :columns="search_columns"
                                    :rows="search_rows"
                                    compactMode
                                    style-class="vgt-table striped"
                                    :pagination-options="{
                                      enabled: true,
                                      mode: 'records',
                                      perPage: 100,
                                      position: 'top',
                                      perPageDropdown: [10, 30, 50, 100],
                                      dropdownAllowAll: false,
                                      nextLabel: '次',
                                      prevLabel: '前',
                                      rowsPerPageLabel: '表示数',
                                      ofLabel: 'の',
                                      pageLabel: 'ページ', // for 'pages' mode
                                      allLabel: '全て',
                                      infoFn: (params) => `${params.firstRecordOnPage} - ${params.lastRecordOnPage}`,
                                    }"
                                  >
                                    <template #table-row="props">
                                      <span v-if="props.column.field == 'action'">
                                        <button class="button-outline" @click="orderlink(props.row.id)">{{props.row.action}}</button>
                                      </span>
                                      <span v-if="props.column.field == 'cont_link'">
                                        <button class="button-outline" @click="order_report(props.row.id)">{{props.row.cont_link}}</button>
                                      </span>
                                      <span v-if="props.column.field == 'attach_link'">
                                        <button class="button-outline">
                                          {{props.row.attach_link}}

                                          <v-overlay
                                            activator="parent"
                                            location-strategy="connected"
                                            scroll-strategy="close"
                                          >
                                            <v-card>
                                              <div>
                                                <ul class="flex">
                                                  <li class="flex-col" v-for="(attach_f,index) in attach_files.value" :key="index">
                                                    <div v-if="props.row.id == attach_f.fields.no">
                                                      <div style="position: relative;">
                                                        <img class="file_icon" src="/media/documents/production/file.png" @dblclick="download_File(attach_f.pk,attach_f.fields.attach)">
                                                      </div>
                                                      <span>{{ attach_f.fields.role_name }}</span>
                                                    </div>
                                                  </li>
                                                </ul>
                                              </div>
                                            </v-card>
                                          </v-overlay>
                                        </button>
                                      </span>
                                    </template>
                                    <template #emptystate>
                                      検索結果は0件です
                                    </template>
                                  </vue-good-table>
                                </div>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-window-item>
                </v-window>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-layout>
  </v-card>
</template>


<style scoped>
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform .2s ease-in-out;
}

.button-outline {
  background-color: #0a5d8a;
  width: 70px;
  height: 30px;
  color: #FFFFFF;
  font-size: 10px;
  border-radius: 10px;
}

.error_class {
  padding: 10px;
  color: red;
  font-size: 14px;
}
</style>

<script setup>
import { ref, reactive, onMounted, computed, inject } from 'vue';
import { useStore } from 'vuex'
import { useRouter } from "vue-router"
import axios from 'axios';
import { Core as YubinBangoCore } from "yubinbango-core2";

const dt = new Date();
const y = dt.getFullYear();
const m = ("00" + (dt.getMonth()+1)).slice(-2);
const d = ("00" + dt.getDate()).slice(-2);
const today = y + "-" + m + "-" + d;

const router = useRouter()
const store = useStore()

const drawer = ref(true)
const rail = ref(true)

const pankuzu = reactive([
  {title: 'ホーム', disabled: false, href: '../home',},
  {title: '受注管理', disabled: true,},
])

const tab = ref(null)
const dialog = ref(false)
const dialog_search = ref(false)
const payment_items = reactive(['California', 'Colorado', 'Florida'])
const status_items = reactive(['一時保存','回覧待ち','取下','差戻','回覧中','完了','削除']) //10 20 21 22 30 90 99
const Posts= reactive([])

const customer_columns = reactive([
  {label: '処理', field: 'action', html: true,},
  {label: 'ID', field: 'id', type: 'number', filterOptions: {enabled: true,}},
  {label: '顧客名', field: 'name', filterOptions: {enabled: true,}},
  {label: '都道府県', field: 'prefectures', filterOptions: {enabled: true,}},
  {label: '状況', field: 'status', filterOptions: {enabled: true,}},
  {label: '契約ID',field: 'contract_id',html: true,filterOptions: {enabled: true,}},
])
const order_columns = reactive([
  {label: '処理',field: 'action',html: true,},
  {label: 'ID',field: 'id',type: 'number',hidden: true,},
  {label: '処理状況',field: 'status',filterOptions: {enabled: true,}},
  {label: '顧客名',field: 'customer',filterOptions: {enabled: true,}},
  {label: '作成者',field: 'created_user',filterOptions: {enabled: true,}},
  {label: '発行日',field: 'date_of_issue',filterOptions: {enabled: true,}},
  {label: '件名',field: 'subject',filterOptions: {enabled: true,}},
  {label: '契約ID',field: 'contract_id',html: true,filterOptions: {enabled: true,}},
  {label: '注文書内容',field: 'cont_link',html: true,},
  {label: '添付',field: 'attach_link',html: true,},
])
const search_columns = reactive([
  {label: '処理',field: 'action', html: true,},
  {label: 'ID', field: 'id', type: 'number', hidden: true,},
  {label: '見積番号',field: 'order_no',filterOptions: {enabled: true,}},
  {label: '処理状況',field: 'status',filterOptions: {enabled: true,}},
  {label: '顧客名',field: 'customer',filterOptions: {enabled: true,}},
  {label: '作成者',field: 'created_user',filterOptions: {enabled: true,}},
  {label: '件名',field: 'subject',filterOptions: {enabled: true,}},
  {label: '契約ID',field: 'contract_id',html: true,filterOptions: {enabled: true,}},
  {label: '見積内容',field: 'cont_link',html: true,},
  {label: '添付',field: 'attach_link',html: true,},
])

//エラーチェック
import { useField, useForm } from 'vee-validate'
const { handleSubmit } = useForm({
  validationSchema: {
    customer (value) {
      if (value?.length >= 1) return true
      return '顧客が選択されておりません。'
    },
    date_of_issue (value) {
      if (value?.length == 10) return true
      return '発行日が正しく入力されておりません。'
    },
    cont_start_date (value) {
      if (value?.length == 10) return true
      return '契約開始日が正しく入力されておりません。'
    },
    cont_end_date (value) {
      if (value?.length == 10) return true
      return '契約終了日が正しく入力されておりません。'
    },
    delivery_date (value) {
      if (value?.length == 10) return true
      return '納品日が正しく入力されておりません。'
    },
  }
})
const submit = handleSubmit(values => {
  console.log(JSON.stringify(values, null, 2))
  order_cre('20')
})



const customer = useField('customer')
const date_of_issue = useField('date_of_issue')
const cont_start_date = useField('cont_start_date')
const cont_end_date = useField('cont_end_date')
const delivery_date = useField('delivery_date')
//const date_of_issue = ref("")
//const customer = ref("")

const header = {
   headers: { Authorization: `JWT ` + store.state.token }
}
const login_id = store.state.login_id
const company_id = store.state.company_id

function logout() {
  axios
    .get("http://127.0.0.1:8000/api/auth/logout/", header)
    .then(response => (this.logout = response.data))
    .catch(error => (this.error = error))
  store.commit("setToken", "")
  store.commit("setlogin", "")
  router.push({ path: "/login"})
}

const postcode = ref("")
const prefectures = ref("")
const municipalities = ref("")
const address1 = ref("")
function searchAddress (postcode) {
  if (!postcode.match(/^\d{3}-?\d{4}$/)) { //入力された値をチェック
    return false
  }
  new YubinBangoCore(this.postcode, (value) => {
    prefectures.value = value.region; // 都道府県 IDを取得したい時はregion_idと記述
    municipalities.value = value.locality; // 市区町村
    address1.value += value.street; // 町域
  });
}


const contract_flg = ref("")
function searchcontract(contract_id) {
  if (contract_id != "") {
    const contract_count = contract_info.value.data.filter(contract => {
      return String(contract.contract_id) == String(contract_id)
    })
    if (contract_count.length >= 1) {
      contract_flg.value = "Y"
    } else {
      contract_flg.value = "N"
    }
  } else {
    contract_flg.value = "A"
  }
}


const search_status = ref([])
const search_contract_id_s = ref("")
const search_contract_id_e = ref("")
const search_create = ref("")
const search_Approval = ref("")
const search_date_of_issue_s = ref("")
const search_date_of_issue_e = ref("")
const search_money_s = ref("")
const search_money_e = ref("")
const search_date_of_circulation_s = ref("")
const search_date_of_circulation_e = ref("")
const search_approval_date_s = ref("")
const search_approval_date_e = ref("")
function search_order() {
  Promise.all(
    [axios.get("http://127.0.0.1:8000/production/login_customer/" + company_id + "/", header),
     axios.get("http://127.0.0.1:8000/production/company_user/" + company_id + "/", header),
     axios.get("http://127.0.0.1:8000/production/order/search/" + login_id + "/", header),
    ]
  )
  .then(([customer_info, com_user_info, search_inf]) => {
    let search_row_s = []
    if (search_status.value.length >=1 ){
      search_status.value.forEach(element => {
        let status_t = ""
        if (element == "作成中"){
          status_t = 10
        } else if (element == "回覧待ち") {
          status_t = 20
        } else if (element == "回覧中") {
          status_t = 30
        } else if (element == "完了") {
          status_t = 90
        } else {
          status_t = "不明"
        }
        const search_rows_t = search_inf.data.filter(search_r => {
          return search_r.status == status_t
        })
        search_row_s = search_row_s.concat(search_rows_t);
      })
    } else {
      search_row_s = search_inf.data
    }

    if (search_contract_id_s.value != "") {
      search_row_s = search_row_s.filter(search_r => {
        return search_r.contract_id >= search_contract_id_s.value
      })
    }
    if (search_contract_id_e.value != "") {
      search_row_s = search_row_s.filter(search_r => {
        return search_r.contract_id <= search_contract_id_e.value
      })
    }


    let search_c = []
    if (search_create.value.length>=1) {
      search_c = search_create.value.split(',');
    }
    let search_rows_m = []
    let name_id = []
    if (search_c.length != 0) {
      search_c.forEach(element => {
        com_user_info.data.forEach(key => {
          if(key.username.indexOf(element)!== -1) {
            name_id.push(key.id)
          }
        })

        name_id.forEach(data => {
          const search_rows_d = search_row_s.filter(search_r => {
            return search_r.created_user == data
          })
          search_rows_m = search_rows_m.concat(search_rows_d);
        })
      })

      search_row_s = Array.from(new Set(search_rows_m))
    }

    search_c = []
    if (search_Approval.value.length>=1) {
      search_c = search_Approval.value.split(',');
    }
    search_rows_m = []
    name_id = []
    if (search_c.length != 0) {
      search_c.forEach(element => {
        com_user_info.data.forEach(key => {
          if(key.username.indexOf(element)!== -1) {
            name_id.push(key.id)
          }
        })

        name_id.forEach(data => {
          const search_rows_d = search_row_s.filter(search_r => {
            return search_r.approval_user == data
          })

          search_rows_m = search_rows_m.concat(search_rows_d);
        })
      })
      search_row_s = Array.from(new Set(search_rows_m))
    }


    if (search_date_of_issue_s.value != "") {
      search_row_s = search_row_s.filter(search_r => {
        return search_r.date_of_issue >= search_date_of_issue_s.value
      })
    }
    if (search_date_of_issue_e.value != "") {
      search_row_s = search_row_s.filter(search_r => {
        return search_r.date_of_issue <= search_date_of_issue_e.value
      })
    }

    if (search_money_s.value != "") {
      search_row_s = search_row_s.filter(search_r => {
        return Number(search_r.total) >= Number(search_money_s.value)
      })
    }
    if (search_money_e.value != "") {
      search_row_s = search_row_s.filter(search_r => {
        return Number(search_r.total) <= Number(search_money_e.value)
      })
    }

    if (search_date_of_circulation_s.value != "") {
      search_row_s = search_row_s.filter(search_r => {
        return search_r.draft_date >= search_date_of_circulation_s.value
      })
    }
    if (search_date_of_circulation_e.value != "") {
      search_row_s = search_row_s.filter(search_r => {
        return search_r.draft_date <= search_date_of_circulation_e.value
      })
    }

    if (search_approval_date_s.value != "") {
      search_row_s = search_row_s.filter(search_r => {
        return search_r.approval_date >= search_approval_date_s.value
      })
    }
    if (search_approval_date_e.value != "") {
      search_row_s = search_row_s.filter(search_r => {
        return search_r.approval_date <= search_approval_date_e.value
      })
    }



    search_data(search_row_s,customer_info,com_user_info)
  })
}

const customer_rows = reactive([]);
function customer_data (customer_info) {
  customer_info.data.forEach(element =>
    customer_rows.push({
      id: String(element["id"]),
      name: String(element["name"]).replace(null, ''),
      postcode: String(element["postcode"]).replace(null, ''),
      prefectures: String(element["prefectures"]).replace(null, ''),
      municipalities: String(element["municipalities"]).replace(null, ''),
      address1: String(element["address1"]).replace(null, ''),
      address2: String(element["address2"]).replace(null, ''),
      action: '選択',
      contract_link: 'リンク',
    })
  )
}

const order_rows = reactive([]);
function order_data (order_info,customer_info,com_user_info) {
  order_info.data.forEach(element =>{
    let status_d = ""
    if (element["status"]==10){
      status_d = "作成中"
    } else if (element["status"]==20) {
      status_d = "回覧待ち"
    } else if (element["status"]==22) {
      status_d = "差戻"
    } else if (element["status"]==21) {
      status_d = "取下"
    } else if (element["status"]==30) {
      status_d = "回覧中"
    } else if (element["status"]==90) {
      status_d = "完了"
    } else {
      status_d = "不明"
    }
    const com_user_info_t = com_user_info.data.filter(com_user => {
      return com_user.id == element["created_user"]
    })

    const customer_info_t = customer_info.data.filter(customer_l => {
      return customer_l.id == element["customer"]
    })
    order_rows.push({
      id: String(element["id"]),
      status: status_d,
      customer: customer_info_t[0].name,
      created_user: com_user_info_t[0].last_name + " " + com_user_info_t[0].first_name,
      date_of_issue: String(element["date_of_issue"]).replace(null, ''),
      subject: String(element["subject"]).replace(null, ''),
      contract_id: String(element["contract_id"]).replace(null, ''),
      action: '選択',
      cont_link: '表示',
      attach_link: '表示',
    })
  })
}

const search_rows = reactive([]);
function search_data(search_info,customer_info,com_user_info) {
  search_rows.length = 0
  search_info.forEach(element => {
    let status_dt = ""
    if (element["status"]==10){
      status_dt = "作成中"
    } else if (element["status"]==20) {
      status_dt = "回覧待ち"
    } else if (element["status"]==22) {
      status_dt = "差戻"
    } else if (element["status"]==21) {
      status_dt = "取下"
    } else if (element["status"]==30) {
      status_dt = "回覧中"
    } else if (element["status"]==90) {
      status_dt = "完了"
    } else {
      status_dt = "不明"
    }
    const com_user_info_t = com_user_info.data.filter(com_user => {
      return com_user.id == element["created_user"]
    })
    const customer_info_t = customer_info.data.filter(customer_l => {
      return customer_l.id == element["customer"]
    })
    search_rows.push({
      id: String(element["id"]),
      order_n: String(element["order_no"]),
      order_no: String(element["order_no"]) + "-" + String(element["version"]),
      status: status_dt,
      customer: customer_info_t[0].name,
      created_user: com_user_info_t[0].last_name + " " + com_user_info_t[0].first_name,
      subject: String(element["subject"]).replace(null, ''),
      contract_id: String(element["contract_id"]).replace(null, ''),
      action: '選択',
      cont_link: '表示',
      attach_link: '表示',
    })
  })
}


const customer_tantou_items = reactive([]);
const customer_id = ref("")
const address2 = ref("")
function cus_sel(customer_i,name,post_c,pref,municip,addr1,addr2) {
  customer_id.value = customer_i
  customer.value.value = name
  postcode.value = post_c
  prefectures.value = pref
  municipalities.value = municip
  address1.value = addr1
  address2.value = addr2
  Promise.all(
    [axios.get("http://127.0.0.1:8000/production/login_tantou/" + customer_i + "/", header),
    ]
  )
  .then(([tantou_info]) => {
    tantou_info.data.forEach(element =>
      customer_tantou_items.push(String(element["tantou"]).replace(null, ''))
    )
  })
}


import saveAs from "file-saver";
function download_File(attach_fi,attach){
  axios({
    url: 'http://localhost:8000/media/' + attach, // File URL Goes Here
    method: 'GET',
    responseType: 'blob',
  }).then((res) => {
      let mineType = res.headers["content-type"];
      const name = res.headers["content-disposition"];
      const blob = new Blob([res.data], { type: mineType });
      saveAs(blob, name);
  });

}

const pro_id = ref("")
const pro_status = ref("")
const pro_hierarchy_parent = ref("")
const role_name = ref("")
function pro_user(stat){
  pro_id.value = stat.id
  pro_status.value = stat.status
  pro_hierarchy_parent.value = stat.hierarchy_parent
  role_name.value = stat.role_name
}


const customer_tantou = ref("")
const subject = ref("")
const payment_item = ref("")
const work_place = ref("")
const work_person = ref("")
const business_manager = ref("")
const contract_id = ref("")
const biko = ref("")
const swal = inject('$swal')
function order_cre (stat) {
  swal({
    title: 'データの登録確認',
    text: '入力内容をサーバへ送信してもよろしいですか?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: '登録する',
    cancelButtonText: 'キャンセル',
  }).then(result => {
    if (result.value) {
      // 登録処理
      const order_data = {
        company: company_id,
        customer: customer_id.value,
        status: stat,
        customer_tantou: customer_tantou.value,
        postcode: postcode.value,
        prefectures: prefectures.value,
        municipalities: municipalities.value,
        address1: address1.value,
        address2: address2.value,
        subject: subject.value,
        payment_item: payment_item.value,
        work_place: work_place.value,
        work_person: work_person.value,
        business_manager: business_manager.value,
        contract_id: contract_id.value,
        totalprice: totalprice.value,
        total: total.value,
        totaltax: totaltax.value,
        tax_district: 10,
        totaltaxprice: totaltaxprice.value,
        biko: biko.value,
        create_date: today,
        created_pg: "order_create",
        created_user: login_id,
        uploaded_pg: "order_create",
        uploaded_user: login_id,
      }
      if (date_of_issue.value.value != '') {
        order_data.date_of_issue = date_of_issue.value.value
      }
      if (date_of_issue.value.value != '') {
        order_data.cont_start_date = cont_start_date.value.value
      }
      if (date_of_issue.value.value != '') {
        order_data.cont_end_date = cont_end_date.value.value
      }
      if (date_of_issue.value.value != '') {
        order_data.delivery_date = delivery_date.value.value
      }
      axios
        .post("http://127.0.0.1:8000/production/order/create/", order_data, header)
        .then(response => {
          var order_updres = response.data;
          order_updres.value = order_updres
          var order_id = response.data.id
          if (forms.length >= 1) {
            var branch_count = 1
            forms.forEach(function(element){
              let price = element.quantity * element.unit_price
              let tax = Math.floor(Number(element.quantity * element.unit_price * 0.1))
              let totalprice = Math.floor(Number(element.quantity * element.unit_price + element.quantity * element.unit_price * 0.1))

              const order_det_data = {
                branch: branch_count,
                name: element.name,
                way: element.way,
                quantity: element.quantity,
                unit: element.unit,
                unit_price: element.unit_price,
                price: price,
                tax: tax,
                tax_district: 10,
                totalprice: totalprice,
                order: order_id,
                created_pg: "order_create",
                created_user: login_id,
                uploaded_pg: "order_create",
                uploaded_user: login_id,
              }
              axios
                .post("http://127.0.0.1:8000/production/order_breakdown/create/", order_det_data, header)
                .then(response => {
                  var order_updres = response.data;
                  order_updres.value = order_updres
                })
              branch_count = branch_count + 1
            })
          }
          axios
            .get("http://127.0.0.1:8000/production/wait_wf_setting/" + company_id + "/" + login_id + "/2/" + order_id + "/", header)
            .then(response => {
              var wait_wf_updres = response.data;
              const wait_wf_info_t = wait_wf_updres.filter(wait_wf => {
                return wait_wf.hierarchy_parent == 10
              })
              pro_user(wait_wf_info_t[0])
            })
            router.push('/order/' + order_id)
          })
        .catch(error => (this.error = error))
      if (stat==10) {
        swal.fire('一時保存しました。!', '', 'success')
      } else {
        swal.fire('登録完了しました。!', '', 'success')
      }
    }
  })
}

function order_report(ind) {
  axios
    .get("http://127.0.0.1:8000/production/order/report/" + ind + "/", header)
    .then(response => {
      var order_r_updres = response.data;
      order_r_updres.value = order_r_updres
      const url = "/media/documents/production/order/" + ind + ".pdf"
      window.open(url, '_blank')
    })
}

//フォーム追加・削除
const forms = reactive([]);
function addForm() {
  forms.push({
    name: "",
    way: "",
    quantity: "",
    unit: "",
    unit_price: "",
    price: "",
  })
}
function deleteForm(index) {
  forms.splice(index, 1);
}

//画面遷移
function orderlink(ind) {
  router.push('/order/' + ind)
}
function contractlink(ind) {
  router.push('/contract/' + ind)
}

const totalprice = computed (() => {
  return forms.reduce((sum, i) => sum + Number(i.quantity * i.unit_price), 0)
})
const total = computed (() => {
  return Math.floor(Number(totalprice.value))
})
const totaltax = computed (() => {
  return Math.floor(total.value * 0.1)
})
const totaltaxprice = computed (() => {
  return Math.floor(Number(total.value + totaltax.value))
})


const attach_files = reactive([]);
const contract_info = reactive([])
const user_name = ref("")
onMounted(() => {
  Promise.all(
    [axios.get("http://127.0.0.1:8000/production/login_info/" + login_id + "/", header),
     axios.get("http://127.0.0.1:8000/production/login_company/" + login_id + "/", header),
     axios.get("http://127.0.0.1:8000/production/login_customer/" + company_id + "/", header),
     axios.get("http://127.0.0.1:8000/production/order/waiting/" + login_id + "/", header),
     axios.get("http://127.0.0.1:8000/production/company_user/" + company_id + "/", header),
     axios.get("http://127.0.0.1:8000/production/contract/search/" + company_id + "/", header),
     axios.get("http://127.0.0.1:8000/production/waiting_attached_get/" + login_id + "/2/", header),
     axios.get("http://127.0.0.1:8000/production/order/search/" + login_id + "/", header),
    ]
  )
  .then(([user_info, login_company, customer_info, order_info, com_user_info,contract_inf,wf_attach_info,search_info]) => {
    let staff = user_info.data[0]["is_staff"]
    store.commit("setcompany", login_company.data[0]["company"])
    if ( staff == 0 ) {
      user_name.value = user_info.data[0]["last_name"] + " " + user_info.data[0]["first_name"]
      contract_info.value = contract_inf
      attach_files.value = JSON.parse(wf_attach_info.data)
      customer_data(customer_info)
      order_data(order_info,customer_info,com_user_info)
      search_data(search_info.data,customer_info,com_user_info)
    } else {
      swal.fire('権限がありません。!', '', 'error')
      router.push('/login')
    }
    date_of_issue.value.value = today;
  })
})




</script>



