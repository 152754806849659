<template>
  <v-card>
    <v-layout>
      <v-navigation-drawer
        v-model="drawer"
        :rail="rail"
        permanent
        @click="rail = false"
      >
        <v-list-item
          :title=user_name
          nav
        >
          <template v-slot:append>
            <v-btn
              variant="text"
              icon="mdi-chevron-left"
              @click.stop="rail = !rail"
            ></v-btn>
          </template>
        </v-list-item>

        <v-divider></v-divider>
        <v-list density="compact" nav>
          <v-list-item prepend-icon="mdi-home-city" title="ホーム" value="home" to="/home"></v-list-item>
          <v-list-item prepend-icon="mdi-account" title="顧客管理" value="customer" to="/customer"></v-list-item>
          <div v-for="authority in authority_id" :key="authority">
            <v-list-item v-if="authority.app_func==3" prepend-icon="mdi-calendar-question" title="SES要員探し" value="Looking_personnel" to="/Looking_personnel"></v-list-item>
            <v-list-item v-if="authority.app_func==4" prepend-icon="mdi-calendar-range" title="SES案件探し" value="Looking_deals" to="/Looking_deals"></v-list-item>
            <v-list-item v-if="authority.app_func==5" prepend-icon="mdi-book-open" title="見積管理" value="estimate" to="/estimate"></v-list-item>
            <v-list-item v-if="authority.app_func==6" prepend-icon="mdi-store" title="受注管理" value="order" to="/order"></v-list-item>
            <v-list-item v-if="authority.app_func==7" prepend-icon="mdi-cart" title="発注管理" value="hachu" to="/hachu"></v-list-item>
            <v-list-item v-if="authority.app_func==8" prepend-icon="mdi-chart-line" title="帳票出力" value="report" to="/report"></v-list-item>
          </div>
          <v-list-item prepend-icon="mdi-logout" title="ログアウト" value="logout" @click="logout()"></v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-main>
        <v-container>
          <v-breadcrumbs :items="pankuzu">
            <template v-slot:divider>
              <v-icon icon="mdi-chevron-right"></v-icon>
            </template>
          </v-breadcrumbs>
          <v-row justify="center">
            <v-col cols="12" sm="12" md="12" lg="12">
              <h1 class="ml-3">SES案件探し詳細</h1>
              <div class="mb-5">
                <v-form>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12" lg="12">
                        <v-textarea
                          v-model="massage"
                          label="応募内容"
                          placeholder="記載内容が応募者にメール送付されます。"
                          rows="5"
                          maxlength="2000"
                          counter
                          clear-icon="mdi-close-circle"
                          clearable
                        ></v-textarea>
                        <v-btn class="ma-3" variant="outlined" @click="detail_send()">送付</v-btn>
                      </v-col>
                    </v-row>
                    <div v-for="publish_r in publish_rep.value" :key="publish_r.id">
                      送信日時：{{ format(publish_r.create_at) }}
                      <v-textarea
                        label="送信内容"
                        rows="5"
                        disabled
                        :model-value="publish_r.content"
                      ></v-textarea>
                    </div>
                    <v-row justify="center">
                      <v-col cols="12" sm="12" md="6" lg="6">
                        <v-text-field
                          v-model="customer_name"
                          label="顧客名"
                          placeholder="Nuage"
                          clear-icon="mdi-close-circle"
                          clearable
                          disabled
                        ></v-text-field>
                        <v-text-field
                          v-model="prefectures"
                          label="都道府県"
                          placeholder="東京都"
                          clear-icon="mdi-close-circle"
                          clearable
                          disabled
                        ></v-text-field>
                        <v-row>
                          <v-col cols="12" sm="12" md="5" lg="5">
                            <v-text-field
                              v-model="start_date"
                              label="掲載開始日"
                              type="date"
                              clear-icon="mdi-close-circle"
                              clearable
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="2" lg="2" class="disp_none">
                            <v-btn
                              dark
                              small
                              color="grey"
                              class="ma-2"
                            >
                              <v-icon dark>mdi-minus</v-icon>
                            </v-btn>
                          </v-col>
                          <v-col cols="12" sm="12" md="5" lg="5">
                            <v-text-field
                              v-model="end_date"
                              label="掲載終了日"
                              type="date"
                              clear-icon="mdi-close-circle"
                              clearable
                              disabled
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-text-field
                          v-model="project_title"
                          label="案件タイトル"
                          placeholder="○○開発業務"
                          clear-icon="mdi-close-circle"
                          clearable
                          disabled
                        ></v-text-field>
                        <v-text-field
                          v-model="work_content"
                          label="作業内容"
                          placeholder="基本設計、詳細設計、製造、障害調査など"
                          clear-icon="mdi-close-circle"
                          clearable
                          disabled
                        ></v-text-field>
                        <v-row>
                          <v-col cols="12" sm="12" md="5" lg="5">
                            <v-text-field
                              v-model="period_start"
                              label="期間開始日"
                              type="date"
                              clear-icon="mdi-close-circle"
                              clearable
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="2" lg="2" class="disp_none">
                            <v-btn
                              dark
                              small
                              color="grey"
                              class="ma-2"
                            >
                              <v-icon dark>mdi-minus</v-icon>
                            </v-btn>
                          </v-col>
                          <v-col cols="12" sm="12" md="5" lg="5">
                            <v-text-field
                              v-model="period_end"
                              label="期間終了日"
                              type="date"
                              clear-icon="mdi-close-circle"
                              clearable
                              disabled
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-text-field
                          v-model="required_skill"
                          label="必要スキル"
                          placeholder="pythonの業務経験3年以上"
                          clear-icon="mdi-close-circle"
                          clearable
                          disabled
                        ></v-text-field>
                        <v-text-field
                          v-model="conditions"
                          label="基本条件"
                          placeholder="コミュニケーションスキル必須"
                          clear-icon="mdi-close-circle"
                          clearable
                          disabled
                        ></v-text-field>
                        <v-text-field
                          v-model="closing_time"
                          label="終業時間"
                          placeholder="9:00～18:00（内休憩1時間）"
                          clear-icon="mdi-close-circle"
                          clearable
                          disabled
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="6" lg="6">
                        <v-text-field
                          v-model="span_time"
                          label="時間幅"
                          placeholder="140～160時間、範囲外の場合は別途清算"
                          clear-icon="mdi-close-circle"
                          clearable
                          disabled
                        ></v-text-field>
                        <v-checkbox
                          v-model="nationality"
                          label="外国籍OK"
                          clear-icon="mdi-close-circle"
                          clearable
                          disabled
                        ></v-checkbox>
                        <v-row>
                          <v-col cols="12" sm="12" md="5" lg="5">
                            <v-text-field
                              v-model="age_from"
                              label="年齢から"
                              type="number"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="2" lg="2" class="disp_none">
                            <v-btn
                              dark
                              small
                              color="grey"
                              class="ma-2"
                            >
                              <v-icon dark>mdi-minus</v-icon>
                            </v-btn>
                          </v-col>
                          <v-col cols="12" sm="12" md="5" lg="5">
                            <v-text-field
                              v-model="age_to"
                              label="年齢まで"
                              type="number"
                              disabled
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-text-field
                          v-model="interview"
                          label="面談回数"
                          type="number"
                          suffix="回"
                          disabled
                        ></v-text-field>
                        <v-text-field
                          v-model="interview_method"
                          label="面談方法"
                          placeholder="web会議"
                          clear-icon="mdi-close-circle"
                          clearable
                          disabled
                        ></v-text-field>
                        <v-text-field
                          v-model="nearest_station"
                          label="最寄駅"
                          placeholder="新宿"
                          clear-icon="mdi-close-circle"
                          clearable
                          disabled
                        ></v-text-field>
                        <v-radio-group
                          label="作業形態"
                          v-model="work_style"
                          inline
                          disabled
                        >
                          <v-radio
                            label="常駐  "
                            value="1"
                          ></v-radio>
                          <v-radio
                            label="リモート併用  "
                            value="2"
                          ></v-radio>
                          <v-radio
                            label="リモート  "
                            value="3"
                          ></v-radio>
                        </v-radio-group>
                        <v-text-field
                          v-model="work_place"
                          label="作業場所"
                          placeholder="新宿徒歩5分、出社は週1程度"
                          clear-icon="mdi-close-circle"
                          clearable
                          disabled
                        ></v-text-field>
                        <v-row>
                          <v-col cols="12" sm="12" md="5" lg="5">
                            <v-text-field
                              v-model="price_from"
                              label="単価から"
                              type="number"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="2" lg="2" class="disp_none">
                            <v-btn
                              dark
                              small
                              color="grey"
                              class="ma-2"
                            >
                              <v-icon dark>mdi-minus</v-icon>
                            </v-btn>
                          </v-col>
                          <v-col cols="12" sm="12" md="5" lg="5">
                            <v-text-field
                              v-model="price_to"
                              label="単価まで"
                              type="number"
                              disabled
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="12" md="12" lg="12">
                        <v-textarea
                          v-model="remarks"
                          label="備考"
                          placeholder="詳細に記載したいときにお使いください。"
                          rows="15"
                          clear-icon="mdi-close-circle"
                          clearable
                          disabled
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-layout>
  </v-card>
</template>

<style lang="scss" scoped>
.button-outline {
  background-color: #0a5d8a;
  width: 70px;
  height: 30px;
  color: #FFFFFF;
  font-size: 10px;
  border-radius: 10px;
}

.disp_none {
  @include display_pc {
    display: block !important;
  }
  @include display_tab {
    display: none !important;
  }
  @include display_sp {
    display: none !important;
  }
}
</style>

<script setup>
import { ref, reactive, onMounted, inject } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex'
import { useRouter, useRoute } from "vue-router"
import dayjs from "dayjs";
dayjs.locale("ja");

const router = useRouter()
const route = useRoute()
const store = useStore()
const swal = inject('$swal')

const dt = new Date();
const y = dt.getFullYear();
const m = ("00" + (dt.getMonth()+1)).slice(-2);
const d = ("00" + dt.getDate()).slice(-2);
const today = y + "-" + m + "-" + d;

const drawer = ref(true)
const rail = ref(true)

const login_id = store.state.login_id
const company_id = store.state.company_id
const authority_id = store.state.authority_id
const publish_id = route.params.publish_id

const massage = ref("")
const anonymous_flg = ref(true)
const customer_name = ref("")
const customer_flg = ref("")
const prefectures = ref("")
const status = ref("")
const start_date = ref(today)
const end_date = ref("")
const project_title = ref("")
const work_content = ref("")
const period_start = ref("")
const period_end = ref("")
const required_skill = ref("")
const conditions = ref("")
const closing_time = ref("")
const span_time = ref("")
const age_from = ref()
const age_to = ref()
const nationality = ref("")
const interview = ref()
const interview_method = ref("")
const nearest_station = ref("")
const work_style = ref("")
const work_place = ref("")
const price_from = ref()
const price_to = ref()
const mail_send = ref("")
const remarks = ref("")

const pankuzu = reactive([
  {title: 'ホーム', disabled: false, href: '../home',},
  {title: 'SES案件探し', disabled: false, href: '../Looking_deals',},
  {title: 'SES案件探し詳細', disabled: true,},
])

const header = {
   headers: { Authorization: `JWT ` + store.state.token }
}


function logout() {
  axios
    .get("https://nuage-sys.com/api/auth/logout/", header)
    .then(response => (logout.value = response.data))
    .catch(error => (this.error = error))
  store.commit("setToken", "")
  store.commit("setlogin", "")
  router.push({ path: "/"})
}

function format(date) {
  date = dayjs(date).format("YYYY/MM/DD HH:mm:ss");
  return date
}

function detail_send() {
  swal({
    title: '応募確認',
    text: '応募内容を案件募集担当者へ送信してもよろしいですか?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: '送信する',
    cancelButtonText: 'キャンセル',
  }).then(result => {
    if (result.value) {
      const publish_rep_data = {
        company: company_id,
        ses_publish: publish_id,
        content: massage.value,
        mail_send: mail_send.value,
        create_user: login_id,
        uploaded_user: login_id,
      }
      axios
        .post("https://nuage-sys.com/ses/publish/detail/reply/", publish_rep_data, header)
        .then(response => {
          var publish_updres = response.data;
          axios
            .get("https://nuage-sys.com/ses/publish/detail/rep_send/" + publish_updres.id + "/", header)
            .then(response => {
              var publish_updres = response.data;
              publish_updres.value = publish_updres
              swal.fire('送信完了しました。!', '', 'success')
              router.push('/Looking_deals')
            })
            .catch(error => (this.error = error))
        })
        .catch(error => (this.error = error))
    }
  })
}


const publish_rep = reactive([])
const user_name = ref("")
onMounted(() => {
  Promise.all(
    [axios.get("https://nuage-sys.com/production/login_info/" + login_id + "/", header),
     axios.get("https://nuage-sys.com/production/login_company/" + login_id + "/", header),
     axios.get("https://nuage-sys.com/ses/publish/detail/" + publish_id + "/", header),
     axios.get("https://nuage-sys.com/ses/publish/reply_r/" + company_id + "/", header),
    ]
  )
  .then(([user_info, login_company, publish_info, publish_rep_info]) => {
    store.commit("setcompany", login_company.data[0]["company"])
    let login_authority = store.state.authority_id
    if ( login_authority.length != 0 ) {
      user_name.value = user_info.data[0]["last_name"] + " " + user_info.data[0]["first_name"]
      if (company_id == publish_info.data["company"]) {
        publish_info.value = publish_info.data
        const publish = publish_rep_info.data.filter(publish_re => {
          return publish_re.ses_publish == publish_id
        })
        publish_rep.value = publish
        if (publish_info.data["anonymous_flg"] == 1){
          anonymous_flg.value = true
        } else {
          anonymous_flg.value = false
        }
        if (publish_info.data["customer_flg"] == 1) {
          customer_name.value = String(publish_info.data["customer_name"]).replace(null, '')
        } else {
          customer_name.value = "非表示"
        }
        if (publish_info.data["customer_flg"] == 1){
          customer_flg.value = true
        } else {
          customer_flg.value = false
        }
        prefectures.value = publish_info.data["prefectures"]
        status.value = publish_info.data["status"]
        start_date.value = publish_info.data["start_date"]
        end_date.value = publish_info.data["end_date"]
        project_title.value = publish_info.data["project_title"]
        work_content.value = publish_info.data["work_content"]
        period_start.value = publish_info.data["period_start"]
        period_end.value = publish_info.data["period_end"]
        required_skill.value = publish_info.data["required_skill"]
        conditions.value = publish_info.data["conditions"]
        closing_time.value = publish_info.data["closing_time"]
        span_time.value = publish_info.data["span_time"]
        age_from.value = publish_info.data["age_from"]
        age_to.value = publish_info.data["age_to"]
        if (publish_info.data["nationality"] == 1){
          nationality.value = true
        } else {
          nationality.value = false
        }
        interview.value = publish_info.data["interview"]
        interview_method.value = publish_info.data["interview_method"]
        nearest_station.value = publish_info.data["nearest_station"]
        work_style.value = String(publish_info.data["work_style"])
        work_place.value = publish_info.data["work_place"]
        price_from.value = publish_info.data["price_from"]
        price_to.value = publish_info.data["price_to"]
        mail_send.value = publish_info.data["mail_send"]
        remarks.value = publish_info.data["remarks"]
      }
    } else {
      swal.fire('権限がありません。!', '', 'error')
      router.push('/')
    }
  })
  .catch(error => {
    console.log(error)
  })
})

</script>