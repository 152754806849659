<template>
  <div>
    <Header></Header>
    <Looking_personnel></Looking_personnel>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/modules/Header.vue';
import Footer from '@/components/modules/Footer.vue';
import Looking_personnel from '@/components/modules/looking_personnel.vue';

export default {
  components: {
    Header,
    Footer,
    Looking_personnel,
  },
};
</script>

