<template>
  <div>
    <Header></Header>
    <Looking_deals_detail></Looking_deals_detail>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/modules/Header.vue';
import Footer from '@/components/modules/Footer.vue';
import Looking_deals_detail from '@/components/modules/looking_deals_detail.vue';

export default {
  components: {
    Header,
    Footer,
    Looking_deals_detail,
  },
};
</script>

