<template>
  <div>
    <Header></Header>
    <Home></Home>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/modules/Header.vue';
import Footer from '@/components/modules/Footer.vue';
import Home from '@/components/modules/home.vue';

export default {
  components: {
    Header,
    Footer,
    Home,
  },
};
</script>

