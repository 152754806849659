<template>
  <VueFlow v-model="elements" />
</template>

<script setup>
import { reactive, onMounted } from 'vue';
import { useStore } from 'vuex'
import { VueFlow } from '@vue-flow/core'
import axios from 'axios';

const store = useStore()

const elements = reactive([]);

const header = {
   headers: { Authorization: `JWT ` + store.state.token }
}

function wf_data(keiyaku_code,keiyaku_id) {
  Promise.all(
    [axios.get("http://127.0.0.1:8000/production/workflow/" + keiyaku_code + "/" + keiyaku_id + "/", header),
     axios.get("http://127.0.0.1:8000/production/workflow_hierarchy/" + keiyaku_code + "/" + keiyaku_id + "/", header),
    ]
  )
  .then(([workflow_info,wf_hie_info]) => {
    workflow_info.data.forEach(element => {
      let type_data = ''
      if (element.hierarchy_parent == 10) {
        type_data = 'input'
      } else if (element.hierarchy_parent == 90) {
        type_data = 'output'
      } else {
        type_data = 'special'
      }

      elements.push({
        id: String(element.source_id + 1),
        type: type_data,
        label: element.role_name + "<br>" + element.user_name,
        position: { x: element.hierarchy_branch * 200 + 100, y: element.hierarchy * 110 }
      })
    })

    wf_hie_info.data.forEach(element => {
      elements.push({
        id: "el-" + String(element.source_id),
        type: element.type,
        source: String(element.source + 1),
        target: String(element.target + 1),
        animated: element.animat
      })
    })

  })
}

onMounted(() => {
new Promise(function(resolve) {
  setTimeout(function() { resolve(
    wf_data(store.state.keiyaku_code,store.state.keiyaku_id)
  ) }, 500)
})
});


</script>

<style>
.vue-flow__node-custom {
  background: #9CA8B3;
  color: #fff;
  padding: 10px;
}
</style>