<template>
  <v-card>
    <v-layout>
      <v-navigation-drawer
        v-model="drawer"
        :rail="rail"
        permanent
        @click="rail = false"
      >
        <v-list-item
          :title=user_name.value
          nav
        >
          <template v-slot:append>
            <v-btn
              variant="text"
              icon="mdi-chevron-left"
              @click.stop="rail = !rail"
            ></v-btn>
          </template>
        </v-list-item>

        <v-divider></v-divider>

        <v-list density="compact" nav>
          <v-list-item prepend-icon="mdi-home-city" title="ホーム" value="home" to="/home"></v-list-item>
          <v-list-item prepend-icon="mdi-account" title="顧客管理" value="customer" to="/customer"></v-list-item>
          <div v-for="authority in authority_id" :key="authority">
            <v-list-item v-if="authority.app_func==3" prepend-icon="mdi-calendar-question" title="SES要員探し" value="Looking_personnel" to="/Looking_personnel"></v-list-item>
            <v-list-item v-if="authority.app_func==4" prepend-icon="mdi-calendar-range" title="SES案件探し" value="Looking_deals" to="/Looking_deals"></v-list-item>
            <v-list-item v-if="authority.app_func==5" prepend-icon="mdi-book-open" title="見積管理" value="estimate" to="/estimate"></v-list-item>
            <v-list-item v-if="authority.app_func==6" prepend-icon="mdi-store" title="受注管理" value="order" to="/order"></v-list-item>
            <v-list-item v-if="authority.app_func==7" prepend-icon="mdi-cart" title="発注管理" value="hachu" to="/hachu"></v-list-item>
            <v-list-item v-if="authority.app_func==8" prepend-icon="mdi-chart-line" title="帳票出力" value="report" to="/report"></v-list-item>
          </div>
          <v-list-item prepend-icon="mdi-logout" title="ログアウト" value="logout" @click="logout()"></v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-main>
        <v-container>
          <v-breadcrumbs :items="pankuzu">
            <template v-slot:divider>
              <v-icon icon="mdi-chevron-right"></v-icon>
            </template>
          </v-breadcrumbs>
          <v-row justify="center">
            <v-col cols="12" sm="12" md="12" lg="12">
              <h1 class="ml-3">顧客担当詳細</h1>
              <span>
                <v-btn class="ma-3" variant="outlined" @click="tantou_upd()">更新</v-btn>
              </span>
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-text-field
                          v-model="tantou"
                          label="担当者名*"
                          clear-icon="mdi-close-circle"
                          clearable
                          required
                        ></v-text-field>
                        <v-text-field
                          v-model="tantou_kana"
                          label="担当者カナ"
                          clear-icon="mdi-close-circle"
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-text-field
                          v-model="tantou_department"
                          label="担当者部署"
                          clear-icon="mdi-close-circle"
                          clearable
                        ></v-text-field>
                        <v-text-field
                          v-model="tantou_position"
                          label="担当者役職"
                          clear-icon="mdi-close-circle"
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-text-field
                          v-model="tantou_tel"
                          label="担当者電話番号"
                          clear-icon="mdi-close-circle"
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                      >
                        <v-text-field
                          v-model="tantou_mail"
                          label="担当者メール"
                          clear-icon="mdi-close-circle"
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="12"
                      >
                        <v-textarea
                          v-model="tantou_comment"
                          label="コメント"
                          rows="10"
                          row-height="15"
                          clear-icon="mdi-close-circle"
                          clearable
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                  <small>*必須項目</small>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-layout>
  </v-card>
</template>

<style>
.button-outline {
  background-color: #0a5d8a;
  width: 70px;
  height: 30px;
  color: #FFFFFF;
  font-size: 10px;
  border-radius: 10px;
}
</style>

<script setup>
import { ref, reactive, onMounted, inject } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex'
import { useRouter, useRoute } from "vue-router"

const router = useRouter()
const route = useRoute()
const store = useStore()
const swal = inject('$swal')

const drawer = ref(true)
const rail = ref(true)

const login_id = store.state.login_id
const customer_id = route.params.customer_id
const tantou_id = route.params.tantou_id
const authority_id = store.state.authority_id

const hp_url = "https://nuage-sys.com"

const pankuzu = reactive([
  {title: 'ホーム', disabled: false, href: '../../../home',},
  {title: '顧客情報', disabled: false, href: '../../../customer',},
  {title: '顧客詳細', disabled: false, href: '../../../customer/' + customer_id ,},
  {title: '顧客担当詳細', disabled: true,},
])

const header = {
   headers: { Authorization: `JWT ` + store.state.token }
}

const tantou = ref("")
const tantou_kana = ref("")
const tantou_department = ref("")
const tantou_position = ref("")
const tantou_tel = ref("")
const tantou_comment = ref("")
const tantou_mail = ref("")

function logout() {
  axios
    .get(hp_url + "/api/auth/logout/", header)
    .then(response => (logout.value = response.data))
    .catch(error => (this.error = error))
  store.commit("setToken", "")
  store.commit("setlogin", "")
  router.push({ path: "/"})
}


function tantou_upd() {
  swal({
    title: 'データの登録確認',
    text: '入力内容をサーバへ送信してもよろしいですか?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: '更新する',
    cancelButtonText: 'キャンセル',
  }).then(result => {
    if (result.value) {
      const tantou_data = {
        customer: customer_id,
        tantou: tantou.value,
        tantou_kana: tantou_kana.value,
        tantou_department: tantou_department.value,
        tantou_position: tantou_position.value,
        tantou_tel: tantou_tel.value,
        comment: tantou_comment.value,
        tantou_mail: tantou_mail.value,
        uploaded_pg: "tantou_detail_update",
        uploaded_user: login_id,
      }
      axios
        .put(hp_url + "/production/tantou/upd/" + tantou_id + "/", tantou_data, header)
        .then(response => {
          var tantou_updres = response.data;
          tantou_updres.value = tantou_updres
        })
        .catch(error => (this.error = error))
      swal.fire('更新完了しました。!', '', 'success')
      router.push('/customer/' + customer_id)
    }
  })
}

const user_name = ref("")
onMounted(() => {
  Promise.all(
    [axios.get(hp_url + "/production/login_info/" + login_id + "/", header),
     axios.get(hp_url + "/production/login_company/" + login_id + "/", header),
     axios.get(hp_url + "/production/login_tantou/" + customer_id + "/", header),
    ]
  )
  .then(([user_info, login_company,tantou_info]) => {
    store.commit("setcompany", login_company.data[0]["company"])
    let login_authority = store.state.authority_id
    if ( login_authority.length != 0 ) {
      user_name.value = user_info.data[0]["last_name"] + " " + user_info.data[0]["first_name"]

      const tantou_row = tantou_info.data.filter(tantou_i => {
        return tantou_i.id == tantou_id
      })
      tantou.value = tantou_row[0].tantou
      tantou_kana.value = tantou_row[0].tantou_kana
      tantou_department.value = tantou_row[0].tantou_department
      tantou_position.value = tantou_row[0].tantou_position
      tantou_tel.value = tantou_row[0].tantou_tel
      tantou_comment.value = tantou_row[0].comment
      tantou_mail.value = tantou_row[0].tantou_mail
    } else {
      swal.fire('権限がありません。!', '', 'error')
      router.push('/')
    }
  })
  .catch(error => {
    console.log(error)
  })
})

</script>