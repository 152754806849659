<template>
  <v-card>
    <v-layout>
      <v-navigation-drawer
        v-model="drawer"
        :rail="rail"
        permanent
        @click="rail = false"
      >
        <v-list-item
          :title=this.user_name
          nav
        >
          <template v-slot:append>
            <v-btn
              variant="text"
              icon="mdi-chevron-left"
              @click.stop="rail = !rail"
            ></v-btn>
          </template>
        </v-list-item>

        <v-divider></v-divider>

        <v-list density="compact" nav>
          <v-list-item prepend-icon="mdi-home-city" title="ホーム" value="home" to="/home"></v-list-item>
          <v-list-item prepend-icon="mdi-account" title="顧客管理" value="customer" to="/customer"></v-list-item>
          <v-list-item prepend-icon="mdi-book-open" title="見積管理" value="estimate" to="/estimate"></v-list-item>
          <v-list-item prepend-icon="mdi-store" title="受注管理" value="order" to="/order"></v-list-item>
          <v-list-item prepend-icon="mdi-cart" title="発注管理" value="hachu" to="/hachu"></v-list-item>
          <v-list-item prepend-icon="mdi-chart-line" title="帳票出力" value="report" to="/report"></v-list-item>
          <v-list-item prepend-icon="mdi-logout" title="ログアウト" value="logout" @click="logout()"></v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-main>
        <v-container>
          <v-breadcrumbs :items="pankuzu">
            <template v-slot:divider>
              <v-icon icon="mdi-chevron-right"></v-icon>
            </template>
          </v-breadcrumbs>
          <v-row justify="center">
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-card>
                <v-tabs
                  v-model="tab"
                  color="deep-purple-accent-4"
                  align-tabs="center"
                >
                  <v-tab :value="1">発注書</v-tab>
                  <v-tab :value="2">処理待ち</v-tab>
                  <v-tab :value="3">検索</v-tab>
                </v-tabs>
                <v-window v-model="tab" class="mb-15">
                  <v-window-item
                    :value="1"
                  >
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12" sm="12" md="12" lg="12">
                          <h1 class="ml-3">発注依頼</h1>
                          <span>
                            <v-btn class="ma-3" variant="outlined" @click="detail_upd('0')">登録</v-btn>
                            <v-btn class="ma-3" variant="outlined" @click="detail_upd(this.customer_info.id)">一時保存</v-btn>
                          </span>
                          <v-container>
                            <v-row justify="center">
                              <v-col cols="12" sm="12" md="12" lg="12">
                              <small>※契約に紐づける受注契約の契約IDを入力してください。</small>
                                <v-text-field
                                  v-model="contract_id"
                                  label="契約ID"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="6" lg="6">
                                <v-text-field
                                  v-model="customer"
                                  label="届け先名"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="6" lg="6">
                                <v-text-field
                                  v-model="customer_tantou"
                                  label="担当者"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="12" lg="12">
                                <v-text-field
                                  v-model="postcode"
                                  label="郵便番号"
                                  @change="searchAddress"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="6" lg="6">
                                <v-text-field
                                  v-model="prefectures"
                                  label="都道府県"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="6" lg="6">
                                <v-text-field
                                  v-model="municipalities"
                                  label="市区町村"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="6" lg="6">
                                <v-text-field
                                  v-model="address1"
                                  label="住所"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" sm="12" md="6" lg="6">
                                <v-text-field
                                  v-model="address2"
                                  label="建物名・ビル名"
                                ></v-text-field>
                              </v-col>

                              <h3>内訳</h3><br>
                              <v-col cols="12" sm="12" md="12" lg="12">
                                <v-container>
                                  <div v-for="(form, index) in forms" :key="index">
                                    <v-row justify="center">
                                      <v-col cols="12" sm="12" md="3" lg="3">
                                        <v-text-field
                                          v-model="forms[index].way"
                                          label="発注先"
                                          placeholder="発注先"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="12" md="3" lg="3">
                                        <v-text-field
                                          v-model="forms[index].name"
                                          label="名称"
                                          placeholder="名称"
                                          required
                                        ></v-text-field>
                                      </v-col>

                                      <v-col cols="6" sm="6" md="2" lg="2">
                                        <v-text-field
                                          v-model="forms[index].quantity"
                                          label="数量"
                                          placeholder="数量"
                                          type="number"
                                          max="999999999999"
                                          min="1"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="6" sm="6" md="1" lg="1">
                                        <v-text-field
                                          v-model="forms[index].unit"
                                          label="単位"
                                          placeholder="単位"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="12" md="2" lg="2">
                                        <v-text-field
                                          v-model="forms[index].unit_price"
                                          label="単価"
                                          placeholder="単価"
                                          type="number"
                                          max="999999999999"
                                          min="1"
                                          suffix="円"
                                        ></v-text-field>
                                      </v-col>
                                      <v-col cols="12" sm="12" md="1" lg="1">
                                        <v-btn
                                          dark
                                          small
                                          color="grey"
                                          class="ma-2"
                                          @click="deleteForm(index)"
                                        >
                                          <v-icon dark>mdi-minus</v-icon>
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </div>
                                </v-container>
                                <v-row justify="center">
                                  <v-btn
                                    dark
                                    small
                                    color="grey"
                                    class="ma-2"
                                    @click="addForm()"
                                  >
                                    <v-icon dark>mdi-plus</v-icon>
                                  </v-btn>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" sm="12" md="9" lg="9">
                                    <v-textarea
                                      v-model="biko"
                                      label="備考"
                                      rows="10"
                                      row-height="15"
                                    ></v-textarea>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="3" lg="3">
                                    <v-row>
                                      <v-col cols="12" sm="12" md="6" lg="6">
                                        小計：
                                      </v-col>
                                      <v-col cols="12" sm="12" md="6" lg="6">
                                        {{total}}円
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12" sm="12" md="6" lg="6">
                                        消費税：
                                      </v-col>
                                      <v-col cols="12" sm="12" md="6" lg="6">
                                        {{totaltax}}円
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col cols="12" sm="12" md="6" lg="6">
                                        &emsp;合計：
                                      </v-col>
                                      <v-col cols="12" sm="12" md="6" lg="6">
                                        {{totaltaxprice}}円
                                      </v-col>
                                    </v-row>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-window-item>
                  <v-window-item
                    :value="2"
                  >
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12">
                          <h1 class="ml-3">処理待ち</h1>
                            <v-container>
                              <v-row justify="center">
                                <v-col cols="12" sm="12" md="12" lg="12">
                                  <div class="mb-15">
                                    <vue-good-table
                                      v-on:selected-rows-change="selectionChanged"
                                      :columns="customer_columns"
                                      :rows="customer_rows"
                                      compactMode
                                      style-class="vgt-table striped"
                                      :select-options="{
                                        enabled: true,
                                        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                                        selectionInfoClass: 'custom-class',
                                        selectionText: 'rows selected',
                                        clearSelectionText: 'clear',
                                        disableSelectInfo: true, // disable the select info panel on top
                                        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                                      }"
                                      :pagination-options="{
                                        enabled: true,
                                        mode: 'records',
                                        perPage: 100,
                                        position: 'top',
                                        perPageDropdown: [10, 30, 50, 100],
                                        dropdownAllowAll: false,
                                        nextLabel: '次',
                                        prevLabel: '前',
                                        rowsPerPageLabel: '表示数',
                                        ofLabel: 'の',
                                        pageLabel: 'ページ', // for 'pages' mode
                                        allLabel: '全て',
                                        infoFn: (params) => `${params.firstRecordOnPage} - ${params.lastRecordOnPage}`,
                                      }"
                                    >
                                      <template #table-row="props">
                                        <span v-if="props.column.field == 'action'">
                                          <button class="button-outline" @click="detail(props.row.id)">{{props.row.action}}</button>
                                        </span>
                                        <span v-if="props.column.field == 'estimatelink'">
                                          <button class="button-outline" @click="estimatelink(props.row.id)">{{props.row.estimatelink}}</button>
                                        </span>
                                      </template>
                                      <template #emptystate>
                                        検索結果は0件です
                                      </template>
                                    </vue-good-table>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-container>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-window-item>
                  <v-window-item
                    :value="3"
                  >
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12">
                          <h1 class="ml-3">検索</h1>
                          <v-col cols="5" sm="3" md="2" lg="2">
                            <v-dialog
                              v-model="dialog"
                              fullscreen
                              :scrim="false"
                              transition="dialog-bottom-transition"
                            >
                              <template v-slot:activator="{ props }">
                                <v-btn
                                  color="primary"
                                  dark
                                  class="ma-2"
                                  v-bind="props"
                                  size="x-large"
                                >
                                  検索条件
                                </v-btn>
                              </template>
                              <v-card>
                                <v-toolbar
                                  dark
                                  color="primary"
                                >
                                  <v-btn
                                    icon
                                    dark
                                    @click="dialog = false"
                                  >
                                    <v-icon>mdi-close</v-icon>
                                  </v-btn>
                                  <v-toolbar-title>検索条件</v-toolbar-title>
                                  <v-spacer></v-spacer>
                                  <v-toolbar-items>
                                    <v-btn
                                      variant="text"
                                      @click="dialog = false"
                                    >
                                      検索
                                    </v-btn>
                                  </v-toolbar-items>
                                </v-toolbar>
                                <v-title>
                                  検索ヒット件数：{{ Posts.length }}{{ text }}件
                                </v-title>
                                <v-container>
                                <v-row class="mb-12">
                                  <v-col cols="12" sm="12" md="12" lg="12">
                                    <v-select
                                      v-model="search_status"
                                      :items="status_items"
                                      chips
                                      label="ステータス"
                                      multiple
                                      @input="search_estimate"
                                    ></v-select>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="5" lg="5">
                                    <v-text-field
                                      v-model="search_contract_id_s"
                                      label="契約ID"
                                      @change="search_estimate"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="1" lg="1">
                                    <v-btn
                                      dark
                                      small
                                      color="grey"
                                      class="ma-2"
                                    >
                                      <v-icon dark>mdi-minus</v-icon>
                                    </v-btn>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="5" lg="5">
                                    <v-text-field
                                      v-model="search_contract_id_e"
                                      label="契約ID"
                                      @change="search_estimate"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="12" lg="12">
                                    <v-text-field
                                      v-model="search_create"
                                      label="作成者"
                                      @change="search_estimate"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="12" lg="12">
                                    <v-text-field
                                      v-model="search_Approval"
                                      label="決裁者"
                                      @change="search_estimate"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="5" lg="5">
                                    <v-text-field
                                      v-model="search_date_of_issue_s"
                                      label="発行日"
                                      type="date"
                                      @change="search_estimate"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="1" lg="1">
                                    <v-btn
                                      dark
                                      small
                                      color="grey"
                                      class="ma-2"
                                    >
                                      <v-icon dark>mdi-minus</v-icon>
                                    </v-btn>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="5" lg="5">
                                    <v-text-field
                                      v-model="search_date_of_issue_e"
                                      label="発行日"
                                      type="date"
                                      @change="search_estimate"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="12" lg="12">
                                    <v-text-field
                                      v-model="search_subject"
                                      label="件名"
                                      @change="search_estimate"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="5" lg="5">
                                    <v-text-field
                                      v-model="search_money_s"
                                      label="金額"
                                      type="number"
                                      max="999999999999"
                                      min="1"
                                      suffix="円"
                                      @change="search_estimate"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="1" lg="1">
                                    <v-btn
                                      dark
                                      small
                                      color="grey"
                                      class="ma-2"
                                    >
                                      <v-icon dark>mdi-minus</v-icon>
                                    </v-btn>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="5" lg="5">
                                    <v-text-field
                                      v-model="search_money_e"
                                      label="金額"
                                      type="number"
                                      max="999999999999"
                                      min="1"
                                      suffix="円"
                                      @change="search_estimate"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="5" lg="5">
                                    <v-text-field
                                      v-model="search_date_of_circulation_s"
                                      label="回覧開始日"
                                      type="date"
                                      @change="search_estimate"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="1" lg="1">
                                    <v-btn
                                      dark
                                      small
                                      color="grey"
                                      class="ma-2"
                                    >
                                      <v-icon dark>mdi-minus</v-icon>
                                    </v-btn>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="5" lg="5">
                                    <v-text-field
                                      v-model="search_date_of_circulation_e"
                                      label="回覧開始日"
                                      type="date"
                                      @change="search_estimate"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="5" lg="5">
                                    <v-text-field
                                      v-model="search_approval_date_s"
                                      label="完了日"
                                      type="date"
                                      @change="search_estimate"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="1" lg="1">
                                    <v-btn
                                      dark
                                      small
                                      color="grey"
                                      class="ma-2"
                                    >
                                      <v-icon dark>mdi-minus</v-icon>
                                    </v-btn>
                                  </v-col>
                                  <v-col cols="12" sm="12" md="5" lg="5">
                                    <v-text-field
                                      v-model="search_approval_date_e"
                                      label="完了日"
                                      type="date"
                                      @change="search_estimate"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card>
                          </v-dialog>
                        </v-col>

                            <v-container>
                              <v-row justify="center">
                                <v-col cols="12" sm="12" md="12" lg="12">
                                  <div class="mb-15">
                                    <vue-good-table
                                      v-on:selected-rows-change="selectionChanged"
                                      :columns="customer_columns"
                                      :rows="customer_rows"
                                      compactMode
                                      style-class="vgt-table striped"
                                      :select-options="{
                                        enabled: true,
                                        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                                        selectionInfoClass: 'custom-class',
                                        selectionText: 'rows selected',
                                        clearSelectionText: 'clear',
                                        disableSelectInfo: true, // disable the select info panel on top
                                        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
                                      }"
                                      :pagination-options="{
                                        enabled: true,
                                        mode: 'records',
                                        perPage: 100,
                                        position: 'top',
                                        perPageDropdown: [10, 30, 50, 100],
                                        dropdownAllowAll: false,
                                        nextLabel: '次',
                                        prevLabel: '前',
                                        rowsPerPageLabel: '表示数',
                                        ofLabel: 'の',
                                        pageLabel: 'ページ', // for 'pages' mode
                                        allLabel: '全て',
                                        infoFn: (params) => `${params.firstRecordOnPage} - ${params.lastRecordOnPage}`,
                                      }"
                                    >
                                      <template #table-row="props">
                                        <span v-if="props.column.field == 'action'">
                                          <button class="button-outline" @click="detail(props.row.id)">{{props.row.action}}</button>
                                        </span>
                                        <span v-if="props.column.field == 'estimatelink'">
                                          <button class="button-outline" @click="estimatelink(props.row.id)">{{props.row.estimatelink}}</button>
                                        </span>
                                      </template>
                                      <template #emptystate>
                                        検索結果は0件です
                                      </template>
                                    </vue-good-table>
                                  </div>
                                </v-col>
                              </v-row>
                            </v-container>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-window-item>
                </v-window>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-layout>
  </v-card>
</template>

<style scoped>
.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform .2s ease-in-out;
}

.button-outline {
  background-color: #0a5d8a;
  width: 70px;
  height: 30px;
  color: #FFFFFF;
  font-size: 10px;
  border-radius: 10px;
}
</style>

<script>
  import axios from 'axios';
  import { Core as YubinBangoCore } from "yubinbango-core2";
  export default {
    data () {
      return {
        pankuzu: [
          {
            title: 'ホーム',
            disabled: false,
            href: '../home',
          },
          {
            title: '発注管理',
            disabled: true,
          },
        ],
        drawer: true,
        rail: true,
        tab: null,
        forms: [],
        customer: '',
        customer_tantou: '',
        postcode: '',
        prefectures: '',
        municipalities: '',
        address1: '',
        address2: '',
        contract_id: '',
        date_of_issue: '',
        subject: '',
        period_s: '',
        period_e: '',
        payment_item: '',
        payment_items: ['California', 'Colorado', 'Florida'],
        work_place: '',
        work_person: '',
        business_manager: '',
        biko: '',
        nebiki: '',
        customer_columns: [
          {
            label: '処理',
            field: 'action',
            html: true,
          },
          {
            label: 'ID',
            field: 'id',
            type: 'number',
            hidden: true,
          },
          {
            label: '状況',
            field: 'status',
            filterOptions: {
              enabled: true,
            }
          },
          {
            label: '顧客名',
            field: 'prefectures',
            filterOptions: {
              enabled: true,
            }
          },
          {
            label: '件名',
            field: 'prefectures',
            filterOptions: {
              enabled: true,
            }
          },
          {
            label: '発行日',
            field: 'prefectures',
            filterOptions: {
              enabled: true,
            }
          },
          {
            label: '契約期間',
            field: 'period',
            filterOptions: {
              enabled: true,
            }
          },
          {
            label: '作成者',
            field: 'prefectures',
            filterOptions: {
              enabled: true,
            }
          },
          {
            label: '契約ID',
            field: 'contractlink',
            html: true,
          },
          {
            label: '見積内容',
            field: 'estimatelink',
            html: true,
          },
          {
            label: '添付',
            field: 'estimatelink',
            html: true,
          },
        ],
        customer_rows:[],
        Posts: [],
        dialog: false,
        notifications: false,
        sound: true,
        widgets: false,
        status_items: ['一時保存','回覧待ち','取下','差戻','処理待ち','処理済み','完了','削除'], //01 10 11 12 20 21 90 99
        search_status: [],
        search_contract_id_s: '',
        search_contract_id_e: '',
        search_create: '',
        search_Approval: '',
        search_date_of_issue_s: '',
        search_date_of_issue_e: '',
        search_subject: '',
        search_money_s: '',
        search_money_e: '',
        search_date_of_circulation_s: '',
        search_date_of_circulation_e: '',
        search_approval_date_s: '',
        search_approval_date_e: '',
      }
    },
    mounted() {
      const header = {
        headers: { Authorization: "JWT " + this.$store.state.token }
      }
      axios
        .get('http://127.0.0.1:8000/api/auth/users/me/', header)
        .then(response => {
          if (response.status !== 200) {
            this.message = 'セッションが切れましたので再度ログインをしてください。'
            this.$router.push('/')
          } else {
            this.$store.commit("setlogin", response.data.id)
            let login_id = this.$store.state.login_id
            let company_id = this.$store.state.company_id
            Promise.all(
              [axios.get("http://127.0.0.1:8000/production/login_info/" + login_id + "/", header),
               axios.get("http://127.0.0.1:8000/production/login_company/" + login_id + "/", header),
               axios.get("http://127.0.0.1:8000/production/login_customer/" + company_id + "/", header),
              ]
            )
            .then(([user_info, login_company, customer_info]) => {
              let staff = user_info.data[0]["is_staff"]
              this.$store.commit("setcompany", login_company.data[0]["company"])
              if ( staff == 0 ) {
                this.user_name = user_info.data[0]["last_name"] + " " + user_info.data[0]["first_name"]
                this.customer_info = customer_info.data

              } else {
                alert('権限がありません')
                this.$router.push('/login')
              }
            })
            .catch(error => {
              console.log(error)
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    computed: {
      totalprice: function() {
        let total = this.forms.reduce((sum, i) => sum + Number(i.quantity * i.unit_price), 0);
        return total;
      },
      total: function() {
        let total = Math.floor(Number(this.totalprice - this.nebiki));
        return total;
      },
      totaltax: function() {
        let total = Math.floor(this.total * 0.1)
        return total;
      },

      totaltaxprice: function() {
        let total = Math.floor(Number(this.total + this.totaltax));
        return total;
      },
    },
    methods: {
      logout: function () {
        const header = {
          headers: { Authorization: "JWT " + this.$store.state.token }
        }
        axios
          .get("http://127.0.0.1:8000/api/auth/logout/", header)
          .then(response => (this.logout = response.data))
          .catch(error => (this.error = error))
        this.$store.commit("setToken", "")
        this.$store.commit("setlogin", "")
        this.$router.push({ path: "/login"})
      },
      detail(ind) {
        this.$router.push('/customer/' + ind)
      },
      estimatelink(ind) {
        this.$router.push('/estimate/' + ind)
      },
      contractlink(ind) {
        this.$router.push('/contract/' + ind)
      },
      searchAddress: function () {
        if (!this.postcode.match(/^\d{3}-?\d{4}$/)) { //入力された値をチェック
          return false
        }
        new YubinBangoCore(this.postcode, (value) => {
          this.prefectures = value.region; // 都道府県 IDを取得したい時はregion_idと記述
          this.municipalities = value.locality; // 市区町村
          this.address1 += value.street; // 町域
        });
      },
      addForm() {
        let form_body = {};
        form_body = {
          name: "",
          way: "",
          quantity: "",
          unit: "",
          unit_price: "",
          price: "",
        };
        this.forms.push(form_body)
      },
      deleteForm (index) {
        this.forms.splice(index, 1);
      },
      search_estimate: function () {
        alert(1)
      },
    }
  }
</script>