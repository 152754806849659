<template>
  <div>
    <Header></Header>
    <Customer_detail></Customer_detail>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/modules/Header.vue';
import Footer from '@/components/modules/Footer.vue';
import Customer_detail from '@/components/modules/customer_detail.vue';

export default {
  components: {
    Header,
    Footer,
    Customer_detail,
  },
};
</script>

