<template>
  <v-card>
    <v-layout>
      <v-navigation-drawer
        v-model="drawer"
        :rail="rail"
        permanent
        @click="rail = false"
      >
        <v-list-item
          :title=user_name
          nav
        >
          <template v-slot:append>
            <v-btn
              variant="text"
              icon="mdi-chevron-left"
              @click.stop="rail = !rail"
            ></v-btn>
          </template>
        </v-list-item>

        <v-divider></v-divider>
        <v-list density="compact" nav>
          <v-list-item prepend-icon="mdi-home-city" title="ホーム" value="home" to="/home"></v-list-item>
          <v-list-item prepend-icon="mdi-account" title="顧客管理" value="customer" to="/customer"></v-list-item>
          <div v-for="authority in authority_id" :key="authority">
            <v-list-item v-if="authority.app_func==3" prepend-icon="mdi-calendar-question" title="SES要員探し" value="Looking_personnel" to="/Looking_personnel"></v-list-item>
            <v-list-item v-if="authority.app_func==4" prepend-icon="mdi-calendar-range" title="SES案件探し" value="Looking_deals" to="/Looking_deals"></v-list-item>
            <v-list-item v-if="authority.app_func==5" prepend-icon="mdi-book-open" title="見積管理" value="estimate" to="/estimate"></v-list-item>
            <v-list-item v-if="authority.app_func==6" prepend-icon="mdi-store" title="受注管理" value="order" to="/order"></v-list-item>
            <v-list-item v-if="authority.app_func==7" prepend-icon="mdi-cart" title="発注管理" value="hachu" to="/hachu"></v-list-item>
            <v-list-item v-if="authority.app_func==8" prepend-icon="mdi-chart-line" title="帳票出力" value="report" to="/report"></v-list-item>
          </div>
          <v-list-item prepend-icon="mdi-logout" title="ログアウト" value="logout" @click="logout()"></v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-main>
        <v-container>
          <v-breadcrumbs :items="pankuzu">
            <template v-slot:divider>
              <v-icon icon="mdi-chevron-right"></v-icon>
            </template>
          </v-breadcrumbs>
          <v-row justify="center">
            <v-col cols="12" sm="12" md="12" lg="12">
              <h1 class="ml-3">SES要員探し</h1>
              <v-btn class="ma-3" variant="outlined" @click="detail(0)">新規追加</v-btn>
              <div class="mb-15">
                <vue-good-table
                  :columns="publish_columns"
                  :rows="publish_rows"
                  :line-numbers="true"
                  compactMode
                  style-class="vgt-table striped"
                  :pagination-options="{
                    enabled: true,
                    mode: 'records',
                    perPage: 100,
                    position: 'top',
                    perPageDropdown: [10, 30, 50, 100],
                    dropdownAllowAll: false,
                    nextLabel: '次',
                    prevLabel: '前',
                    rowsPerPageLabel: '表示数',
                    ofLabel: 'の',
                    pageLabel: 'ページ', // for 'pages' mode
                    allLabel: '全て',
                    infoFn: (params) => `${params.firstRecordOnPage} - ${params.lastRecordOnPage}`,
                  }"
                >
                  <template #table-row="props">
                    <span v-if="props.column.field == 'action'">
                      <button class="button-outline" @click="detail(props.row.id)">{{props.row.action}}</button>
                    </span>
                    <span v-if="props.column.field == 'number_app'">
                      <RouterLink :to="/Looking_personnel/ + props.row.id">{{ props.row.number_app }}</RouterLink>
                    </span>
                  </template>
                  <template #emptystate>
                    検索結果は0件です
                  </template>
                </vue-good-table>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-main>
    </v-layout>
  </v-card>
</template>

<style>
.button-outline {
  background-color: #0a5d8a;
  width: 70px;
  height: 30px;
  color: #FFFFFF;
  font-size: 10px;
  border-radius: 10px;
}
</style>

<script setup>
import { ref, reactive, onMounted, inject } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex'
import { useRouter } from "vue-router"

const router = useRouter()
const store = useStore()
const swal = inject('$swal')

const drawer = ref(true)
const rail = ref(true)

const login_id = store.state.login_id
const company_id = store.state.company_id
const authority_id = store.state.authority_id

const pankuzu = reactive([
  {title: 'ホーム', disabled: false, href: '../home',},
  {title: 'SES要員探し', disabled: true,},
])

const publish_columns = reactive([
  {label: '処理', field: 'action', html: true,},
  {label: 'ID', field: 'id', type: 'number', hidden: true,},
  {label: '掲載開始日', field: 'publication_start', filterOptions: {enabled: true,}},
  {label: '顧客', field: 'customer', filterOptions: {enabled: true,}},
  {label: '案件タイトル', field: 'project_title', filterOptions: {enabled: true,}},
  {label: '期間開始日', field: 'period', filterOptions: {enabled: true,}},
  {label: '応募件数', field: 'number_app', filterOptions: {enabled: true,}},
])

const header = {
   headers: { Authorization: `JWT ` + store.state.token }
}

const publish_rows = reactive([]);
function publish_data (publish_info,publish_rep_info) {
  publish_info.data.forEach(element => {

    const publish_d = publish_rep_info.data.filter(publish_re => {
      return publish_re.ses_publish == element["id"]
    })

    const publish_c = publish_d.filter((v1, i1, a1) => {
      return a1.findIndex(v => v1.company === v.company) === i1
    })

    publish_rows.push({
      id: String(element["id"]),
      publication_start: String(element["start_date"]).replace(null, ''),
      customer: String(element["customer_name"]).replace(null, ''),
      project_title: String(element["project_title"]).replace(null, ''),
      period: String(element["period_start"]).replace(null, ''),
      action: '修正',
      number_app: publish_c.length,
    })
  })
}

function logout() {
  axios
    .get("https://nuage-sys.com/api/auth/logout/", header)
    .then(response => (logout.value = response.data))
    .catch(error => (this.error = error))
  store.commit("setToken", "")
  store.commit("setlogin", "")
  router.push({ path: "/"})
}

function detail(ind) {
  router.push('/Looking_personnel/' + ind)
}


const user_name = ref("")
onMounted(() => {
  Promise.all(
    [axios.get("https://nuage-sys.com/production/login_info/" + login_id + "/", header),
     axios.get("https://nuage-sys.com/production/login_company/" + login_id + "/", header),
     axios.get("https://nuage-sys.com/ses/publish/" + company_id + "/", header),
     axios.get("https://nuage-sys.com/ses/publish/reply/" + company_id + "/", header),
    ]
  )
  .then(([user_info, login_company, publish_info, publish_rep_info]) => {
    store.commit("setcompany", login_company.data[0]["company"])
    let login_authority = store.state.authority_id
    if ( login_authority.length != 0 ) {
      user_name.value = user_info.data[0]["last_name"] + " " + user_info.data[0]["first_name"]
      publish_data(publish_info,publish_rep_info)
    } else {
      swal.fire('権限がありません。!', '', 'error')
      router.push('/')
    }
  })
  .catch(error => {
    console.log(error)
  })
})

</script>