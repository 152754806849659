<template>
  <div>
    <Header></Header>
    <Looking_deals></Looking_deals>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/modules/Header.vue';
import Footer from '@/components/modules/Footer.vue';
import Looking_deals from '@/components/modules/looking_deals.vue';

export default {
  components: {
    Header,
    Footer,
    Looking_deals,
  },
};
</script>

