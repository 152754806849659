<template>
  <header>
    <v-app-bar
      app
      dark
      color="primary"
    >
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>
        <router-link to="/" class="toolbar-title">
          Nuage
        </router-link>
      </v-toolbar-title>
      <v-tabs>
        <v-tab
          @click="logout()"
        >
          ログアウト
        </v-tab>
      </v-tabs>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group>
          <v-list-item>
            <v-list-item-title @click="logout()">ログアウト</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </header>
</template>


<script>
  import axios from 'axios';
  export default {
    data() {
      return {
        drawer: false,
      }
    },
    methods: {
      logout: function () {
        const header = {
          headers: { Authorization: "JWT " + this.$store.state.token }
        }
        axios
          .get("http://127.0.0.1:8000/api/auth/logout/", header)
          .then(response => (this.logout = response.data))
          .catch(error => (this.error = error))
        this.$store.commit("setToken", "")
        this.$store.commit("setlogin", "")
        this.$router.push({ path: "/"})
      },
    },
  };
</script>

<style lang="scss" scoped>
.v-toolbar-title {

}

// v-toolbar-title 内のリンク色を直す
.toolbar-title {
  overflow: visible !important;
  margin-right: 50px !important;
  font-size:25px;
  color: inherit;
  text-decoration: none;
}
.v-app-bar-nav-icon {
  @include display_pc {
    display: none !important;
  }
}

</style>
