<template>
  <v-sheet
    class="mx-auto mt-8 mb-8 pb-12"
    elevation="8"
    max-width="100%"
    justify="center"
  >
    <v-row justify="center" class="mt-4">
      <v-col cols="12" sm="12" md="10" lg="10">
        <v-container>
          <p v-if="message">{{ message }}</p>
          <v-text-field label="ユーザID" type="text" v-model="username"></v-text-field>
          <v-text-field label="パスワード" type="password" v-model="password"></v-text-field>
          <v-btn outlined @click="login">
            login
          </v-btn>
        </v-container>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<style lang="scss" scoped>

</style>


<script>
  import axios from 'axios';
  export default {
    auth: false,
    //ログイン状態であればリダイレクトする
    middleware({ store, redirect }) {
      if(store.$auth.loggedIn) {
        redirect(('/home'));
      }
    },

    data () {
      return {
        username: "",
        password: "",
        message: '',
      }
    },
    methods: {
      login() {
        const data = {
          email: this.username,
          password: this.password
        }
        const hp_url = "https://nuage-sys.com"
        axios
          .post(hp_url + '/api/auth/jwt/create', data)
          .then(response => {
            if (response.status !== 200) {
              this.message = 'ログインに失敗しました'
            } else {
              this.$store.commit("setToken", response.data["access"])
              this.$router.push('/home')
            }
          })
          .catch(error => {
            console.log(error)
            if (error.status !== 200) {
              this.message = 'ログインに失敗しました'
            }
          })
      }
    }
  }

</script>
