<template>
  <v-footer class="bg-grey-lighten-1 pt-13 pb-10">
    <v-row justify="center" no-gutters>
      <v-col class="text-center" cols="12">
        <strong>Copyright &copy; 2023 Nuage</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>

</script>